import { createContext, useContext } from "react";

import { CountryVersion, Language } from "constants/enum";

type ContextProps = {
    version: CountryVersion;
    language?: Language;
};

export const LocationContext = createContext<ContextProps>({
    version: CountryVersion.SPAIN,
    language: Language.SPAIN,
});

export const useLocationContext = () => useContext(LocationContext);

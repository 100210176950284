module.exports = {
    languages: {
        it: "it",
        es: "es",
        pt: "pt",
        fr: "fr",
        en: "en",
        ca: "ca",
        uk: "uk",
        de: "de",
        us: "en-US",
    },
};

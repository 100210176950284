import { COOKIES } from "constants/enum";
import { setCookie, getCookiesValueByName } from "utils/cookie";

const COOKIE_POLICY_VALUE = "accepted";

export const getIsCookiesPolicyAccepted = (): boolean => {
    let cookiesPolicyAccepted = "";

    try {
        cookiesPolicyAccepted = decodeURIComponent(
            getCookiesValueByName(COOKIES.COOKIES_POLICY)
        );
    } catch (e) {
        console.error(e);
    }

    return Boolean(cookiesPolicyAccepted);
};

export const setCookiesPolicyAccepted = () => {
    const now = new Date();
    now.setMonth(now.getMonth() + 12);
    const options = { path: "/", expires: now.toUTCString() };

    setCookie(COOKIES.COOKIES_POLICY, COOKIE_POLICY_VALUE, options);
};

import { FC } from "react";
import { Link, Picture } from "@smile2impress/components";

export const BadgetAward: FC = () => {
    return (
        <Link
            href="https://www.frameweb.com/article/proudly-announcing-the-full-list-of-frame-awards-winners"
            target="_blank"
            rel="noopener nofollow"
        >
            <Picture
                images={{ png: "/images/frame-awards.png" }}
                pictureProps={{ height: 6 }}
            />
        </Link>
    );
};

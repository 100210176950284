import { useContext, createContext } from "react";

const CountdownContext = createContext({
    countdownValue: 0,
    setCountdownValue: (_value: number) => {},
});

export const CountdownContextProvider = CountdownContext.Provider;

export const useCountdownContext = () => useContext(CountdownContext);

import { Translate } from "next-translate";

import { pathConfig } from "navigation/pathConfig";
import { CommonPageContentProps } from "content/content.types";

const cookiesPolicy: any = (
    t: Translate,
    { version, language }: CommonPageContentProps
) => ({
    banner: {
        content: t("global:cookiesBannerContent"),
        link: {
            name: t("global:cookiesPolicy"),
            href: pathConfig.cookiesPolicy[version]?.[language],
        },
        accept: t("global:cookiesAcceptText"),
        settings: t("global:cookiesSettings"),
    },
    popup: {
        title: t("global:privacyPreferencesCentreTitle"),
        accept: t("global:cookiesAcceptText"),
        settings: t("global:cookiesConfirmSettings"),
        content: [
            {
                name: t("global:cookiesPrivacyNameLink"),
                content: t("global:cookiesPrivacyContent"),
                link: {
                    name: t("global:cookiesPolicy"),
                    href: pathConfig.cookiesPolicy[version]?.[language],
                },
            },
            {
                name: t("global:cookiesStrictlyNecessaryTitle"),
                status: t("global:cookiesNecessaryActiveStatus"),
                content: t("global:cookiesBannerNecessaryContent"),
                listTitles: ["Cookie Name:", "Domain:", "Provider:"],
                listCookies: [
                    ["calendario", "smile2impress.com", "Smile2Impress"],
                    ["smile2impress.com", "Smile2Impress"],
                    ["email", "smile2impress.com", "Smile2Impress"],
                    ["firstname", "smile2impress.com", "Smile2Impress"],
                    ["impress_city", "smile2impress.com", "Smile2Impress"],
                    [
                        "impress_preferred_lang",
                        "smile2impress.com",
                        "Smile2Impress",
                    ],
                    [
                        "impress_preferred_region",
                        "smile2impress.com",
                        "Smile2Impress",
                    ],
                    ["impress_region", "smile2impress.com", "Smile2Impress"],
                    [
                        "impress_utm_service_cookie",
                        "smile2impress.com",
                        "Smile2Impress",
                    ],
                    ["lastname", "smile2impress.com", "Smile2Impress"],
                    ["location", "smile2impress.com", "Smile2Impress"],
                    ["phone", "smile2impress.com", "Smile2Impress"],
                ],
            },
            {
                name: t("global:cookiesAnalysisTitle"),
                switcher: {
                    active: t("global:cookiesActiveStatus"),
                    inactive: t("global:cookiesInactiveStatus"),
                },
                content: t("global:cookiesAnalysisContent"),
                listTitles: ["Cookie Name:", "Domain:", "Provider:"],
                listCookies: [
                    ["fr", "facebook.com", "Facebook"],
                    ["_ga", "google.com", "Google"],
                    ["_gid", "google.com", "Google"],
                    ["bcookie", "linkedin.com", "LinkedIn"],
                    ["li_gc", "linkedin.com", "LinkedIn"],
                    ["lissc", "linkedin.com", "LinkedIn"],
                ],
            },
        ],
    },
});

export default cookiesPolicy;

import { Translate } from "next-translate";

import { pathConfig } from "navigation/pathConfig";
import { PageContentProps } from "content/content.types";

export const menuContent = (t: Translate, { version }: PageContentProps) => [
    {
        id: "whyImpress",
        name: t("global:whyImpressPageTitle"),
        link: pathConfig.whyImpress[version],
    },
    {
        id: "howItWorks",
        name: t("global:howItWorksPageTitle"),
        link: pathConfig.howItWorks[version],
    },
    {
        id: "prices",
        name: t("global:pricePageTitle"),
        link: pathConfig.prices[version],
    },
    {
        id: "results",
        name: t("global:resultsPageTitle"),
        link: pathConfig.results[version],
    },
    {
        id: "about",
        name: t("global:whoWeArePageTitle"),
        link: pathConfig.about[version],
    },
    {
        id: "locations",
        name: t("global:locationPageTitle"),
        link: pathConfig.locations[version],
    },
];

export default menuContent;

const { versions } = require("../configs/versionsConfig");
const { languages } = require("../configs/languagesConfig");

const {
    uk,
    us,
    italy,
    spain,
    france,
    germany,
    ukraine,
    portugal,
} = versions;

const { fr, it, pt, es, en, de } = languages;

const blog = {
    [france]: `/${fr}/blog`,
    [italy]: `/${it}/blog`,
    [portugal]: `/${pt}/blog`,
    [spain]: `/${es}/blog`,
    [uk]: `/${uk}/blog`,
    [us]: `/${us}/blog`,
};

module.exports = {
    pathConfig: {
        main: {
            [france]: `/${fr}`,
            [germany]: "/de",
            [italy]: `/${it}`,
            [portugal]: `/${pt}`,
            [spain]: `/${es}`,
            [uk]: `/${uk}`,
            [ukraine]: "/ua",
            [us]: "/us",
        },
        prices: {
            [france]: `/${fr}/prix`,
            [germany]: "/de/preise",
            [italy]: `/${it}/prezzi-it`,
            [portugal]: `/${pt}/precos-pt`,
            [spain]: `/${es}/precios-es`,
            [uk]: `/${uk}/prices`,
            [ukraine]: "/ua/tsiny",
            [us]: "/us/prices",
        },
        pressReleases: {
            [france]: "https://press.smile2impress.com/",
            [germany]: "https://press.smile2impress.com/",
            [italy]: "https://press.smile2impress.com/",
            [portugal]: "https://press.smile2impress.com/",
            [spain]: "https://press.smile2impress.com/",
            [uk]: "https://press.smile2impress.com/",
            [ukraine]: "https://press.smile2impress.com/",
            [us]: "https://press.smile2impress.com/",
        },

        howItWorks: {
            [france]: `/${fr}/comment-ca-marche`,
            [germany]: "/de/so-funktionierts",
            [italy]: `/${it}/come-funziona-ortodonzia-invisibile`,
            [portugal]: `/${pt}/como-funciona-ortodontia-invisivel`,
            [spain]: `/${es}/como-funciona-ortodoncia-invisible`,
            [uk]: `/${uk}/how-it-works`,
            [ukraine]: "/ua/yak-tse-pratsyuye",
            [us]: "/us/how-it-works",
        },
        locations: {
            [france]: `/${fr}/emplacements`,
            [germany]: "/de/standorte",
            [italy]: `/${it}/le-nostre-sedi-impress`,
            [portugal]: `/${pt}/nossas-localizacoes-impress`,
            [spain]: `/${es}/nuestras-ubicaciones-impress`,
            [uk]: `/${uk}/locations`,
            [ukraine]: "/ua/lokatsiyi",
            [us]: "/us/locations",
        },
        retainersSubscription: {
            [france]: `/${fr}/gouttiere-de-contention-amovible`,
            [spain]: `/${es}/suscripcion-retenedores-removibles`,
            [portugal]: `/${pt}/aparelho-contencao-removivel-subscricoes`,
            [italy]: `/${it}/apparecchio-contenzione-trasparente-abbonamento`,
            [uk]: `/${uk}/clear-retainers-subscription`,
            [germany]: `/${de}/retentionsschiene-abo-modelle`,
            [ukraine]: `/ua/pidpyska-na-reteynery`,
        },
        about: {
            [france]: `/${fr}/a-propos-de-nous`,
            [germany]: "/de/uber-uns",
            [italy]: `/${it}/sul-nostro-conto-impress`,
            [portugal]: `/${pt}/sobre-nos-impress`,
            [spain]: `/${es}/sobre-nosotros-impress`,
            [uk]: `/${uk}/who-we-are`,
            [us]: `/${us}/about-us`,
            // [ukraine]: "/ua/khto-my",
        },
        teen: {
            [france]: `/${fr}/teen`,
            [italy]: `/${it}/teen`,
            [portugal]: `/${pt}/teen`,
            [spain]: `/${es}/teen`,
            [uk]: `/${uk}/teen`,
        },
        thanksSign: {
            [spain]: `/${es}/gracias-firmar`,
            [italy]: `/${it}/grazie-firmare`,
            [portugal]: `/${pt}/obrigado-assinar`,
            [france]: `/${fr}/merci-signer`,
            [uk]: `/${uk}/thanks-sign`,
            [ukraine]: `/ua/dyakuyu-pidpisano`,
        },
        thanksText: {
            [ukraine]: "/ua/dyakuyu",
            [germany]: "/de/danke",
        },
        thanksMail: {
            [france]: `/${fr}/merci-email`,
            [italy]: `/${it}/grazie-email`,
            [portugal]: `/${pt}/obrigado-email`,
            [spain]: `/${es}/gracias-email`,
            [uk]: `/${uk}/thanks-email`,
        },

        thanksContact: {
            [france]: "/fr/thanks-contact",
            [italy]: "/it/thanks-contact",
            [portugal]: "/pt/thanks-contact",
            [spain]: "/es/thanks-contact",
            [uk]: "/uk/thanks-contact",
            [ukraine]: "/ua/thanks-contact",
            [germany]: "/de/thanks-contact",
            [us]: "/us/thanks-contact",
        },
        privacyPolicy: {
            [france]: {
                [fr]: "/policy/privacy-policy-france.pdf",
            },
            [italy]: {
                [it]: "/policy/privacy-policy-italy.pdf",
                [en]: "/policy/privacy-policy-italy_en.pdf",
            },
            [portugal]: {
                [pt]: "/policy/privacy-policy-portugal.pdf",
                [en]: "/policy/privacy-policy-portugal_en.pdf",
            },
            [spain]: {
                [es]: "/policy/privacy-policy-spanish.pdf",
                [en]: "/policy/privacy-policy-spanish_en.pdf",
            },
            [uk]: {
                [en]: "/policy/privacy-policy-english.pdf",
            },
            [germany]: {
                [de]: "/policy/privacy-policy-germany.pdf",
            },
            [ukraine]: {
                [uk]: "/policy/privacy-policy-ukraine.pdf",
            },
            [us]: {
                [en]: "/policy/privacy-policy-us.pdf",
            },
        },
        cookiesPolicy: {
            [france]: {
                [fr]: "/policy/cookies-policy-france.pdf",
            },
            [italy]: {
                [it]: "/policy/cookies-policy-italy.pdf",
                [en]: "/policy/cookies-policy-italy_en.pdf",
            },
            [portugal]: {
                [pt]: "/policy/cookies-policy-portugal.pdf",
                [en]: "/policy/cookies-policy-portugal_en.pdf",
            },
            [spain]: {
                [es]: "/policy/cookies-policy-spanish.pdf",
                [en]: "/policy/cookies-policy-spanish_en.pdf",
            },
            [uk]: {
                [en]: "/policy/cookies-policy-english.pdf",
            },
            [ukraine]: {
                [uk]: "/policy/cookies-policy-ukraine.pdf",
            },
            [us]: {
                [en]: "/policy/cookies-policy-us.pdf",
            },
        },
        legalBasis: {
            [france]: {
                [fr]: "/policy/aviso-legal-france.pdf",
            },
            [italy]: {
                [it]: "/policy/aviso-legal-italy.pdf",
                [en]: "/policy/aviso-legal-italy_en.pdf",
            },
            [portugal]: {
                [pt]: "/policy/aviso-legal-portugal.pdf",
                [en]: "/policy/aviso-legal-portugal_en.pdf",
            },
            [spain]: {
                [es]: "/policy/aviso-legal-spanish.pdf",
                [en]: "/policy/aviso-legal-spanish_en.pdf",
            },
            [uk]: {
                [en]: "/policy/aviso-legal-english.pdf",
            },
            [ukraine]: {
                [uk]: "/policy/aviso-legal-ukraine.pdf",
            },
        },
        impressum: {
            [germany]: "/policy/impressum.pdf",
        },

        telehealthConsent: {
            [us]: "/policy/telehealth-consent-us.pdf",
        },

        terms: {
            [us]: "/policy/terms-and-conditions-us.pdf",
        },

        recordConsent: {
            [us]: "/policy/record-taking-consent-us.pdf",
        },

        clinicPage: {
            [france]: `/${fr}/page-clinique`,
            [italy]: `/${it}/clinica-ortodonzia-invisibile`,
            [portugal]: `/${pt}/clinica-ortontica-invisivel`,
            [spain]: `/${es}/clinica-ortodoncia-invisible`,
            [uk]: `/${uk}/clinic-page`,
            [us]: `/${us}/orthodontic-clinics`,
        },

        whyImpress: {
            [france]: `/${fr}/pourquoi-choisir-impress`,
            [italy]: `/${it}/perche-scegliere-impress`,
            // [portugal]: `/${pt}/porque-escolher-impress`,
            [spain]: `/${es}/por-que-elegir-impress`,
            [uk]: `/${uk}/why-impress`,
            [ukraine]: "/ua/chomu-impress",
        },

        results: {
            [france]: `/${fr}/resultats`,
            [germany]: "/de/ergebnisse",
            [italy]: `/${it}/risultati`,
            // [portugal]: `/${pt}/resultados`,
            [spain]: `/${es}/resultados`,
            [uk]: `/${uk}/results`,
            [us]: `/us/results`,
        },

        universalForm: {
            [france]: "/fr/universal-form",
            [italy]: "/it/universal-form",
            [portugal]: "/pt/universal-form",
            [spain]: "/es/universal-form",
            [uk]: "/uk/universal-form",
            [ukraine]: "/ua/universal-form",
            [germany]: "/de/universal-form",
            [us]: "/us/universal-form",
        },

        bookingWidget: {
            [france]: `/${fr}/booking-widget`,
            [italy]: `/${it}/booking-widget`,
            [portugal]: `/${pt}/booking-widget`,
            [spain]: `/${es}/booking-widget`,
            [uk]: `/${uk}/booking-widget`,
            [ukraine]: "/ua/booking-widget",
            [germany]: "/de/booking-widget",
            [us]: "/us/booking-widget",
        },

        universalSelect: {
            [france]: `/${fr}/universal-select`,
            [italy]: `/${it}/universal-select`,
            [portugal]: `/${pt}/universal-select`,
            [spain]: `/${es}/universal-select`,
            [uk]: `/${uk}/universal-select`,
            [ukraine]: "/ua/universal-select",
            [germany]: "/de/universal-select",
            [us]: "/us/universal-select",
        },
        blog: {
            [italy]: `/${it}/blog`,
            [spain]: `/${es}/blog`,
            [germany]: "/de/blog",
            [portugal]: `/${pt}/blog`,
            [france]: `/${fr}/blog`,
            [uk]: `/${uk}/blog`,
            [us]: `/${us}/blog`,
        },
        article_common: {
            [italy]: blog[italy],
            [spain]: blog[spain],
            [germany]: "/de/blog",
            [portugal]: blog[portugal],
            [france]: blog[france],
            [uk]: blog[uk],
            [us]: blog[us],
        },

        firstVisit: {
            [italy]: "/it/first-visit",
            [spain]: "/es/first-visit",
            [portugal]: "/pt/first-visit",
            [france]: "/fr/first-visit",
            [uk]: "/uk/first-visit",
            [us]: "/us/first-visit",
            [ukraine]: "/ua/first-visit",
        },

        c2: {
            [spain]: "/es/c2",
            [italy]: "/it/c2",
            [portugal]: "/pt/c2",
            [france]: "/fr/c2",
            [uk]: "/uk/c2",
            [us]: "/us/c2",
        },

        referral: {
            [spain]: "/es/referral",
            [italy]: "/it/referral",
            [portugal]: "/pt/referral",
            [germany]: "/de/referral",
            [france]: "/fr/referral",
            [uk]: "/uk/referral",
            [us]: "/us/referral",
        },

        referralForFriends: {
            [spain]: "/es/referral-for-friends",
            [italy]: "/it/referral-for-friends",
            [portugal]: "/pt/referral-for-friends",
            [germany]: "/de/referral-for-friends",
            [france]: "/fr/referral-for-friends",
            [uk]: "/uk/referral-for-friends",
            [us]: "/us/referral-for-friends",
        },

        referralTerms: {
            spain: "/policy/bases-legales-referral_es.pdf",
            portugal: "/policy/bases-legales-referral_pt.pdf",
            germany: "/policy/bases-legales-referral_de.pdf",
            france: "/policy/bases-legales-referral_fr.pdf",
            uk: "/policy/bases-legales-referral_uk.pdf",
            italy: "/policy/bases-legales-referral_it.pdf",
        },

        newCity: {
            [spain]: "/es/new-city-advertising",
        },

        advanced: {
            [spain]: "/es/ortodoncia-invisible-casos-dificiles",
            [portugal]: "/pt/ortodontia-invisivel-casos-dificeis",
            [germany]: "/de/kieferorthopaedie-komplexe-faelle",
            [uk]: "/uk/complex-orthodontic-cases",
            [italy]: "/it/ortodonzia-invisibile-casi-difficili",
            [france]: "/fr/orthodontie-invisible-cas-difficiles",
            [ukraine]: "/ua/skladni-vypadky-ortodontiyi",
            [us]: "/us/comprehensive-orthodontic-treatments",
        },

        quiz: {
            [spain]: `/${es}/mala-mordida-sintomas`,
            [portugal]: `/${pt}/ma-oclusao-dentaria-sintomas`,
            [italy]: `/${it}/malocclusione-sintomi`,
            [uk]: `/${uk}/malocclusion-symptoms`,
            [germany]: `/${de}/falscher-biss-symptome`,
        },

        kids: {
            [spain]: `/${es}/ortodoncia-infantil`,
        },
        associatedClinics: {
            [spain]: `/${es}/clinicas-asociadas`,
            [italy]: `/${it}/centri-associati`,
            [uk]: `/${uk}/partner-clinics`,
            [france]: `/${fr}/cliniques-partenaires`,
        },
        blackFridayLp: {
            [spain]: `/es/special/pack-noviembre`,
            [france]: `/fr/special/pack-novembre`,
            [italy]: `/it/special/pachetto-novembre`,
            [uk]: `/uk/special/november-pack`,
            [portugal]: `/pt/special/pack-novembro`,
            [germany]: `/de/special/paket-november`,
            [ukraine]: `/ua/special/nabir-lystopad`,
            [us]: `/us/special/november-pack`,
        },
        kids: {
            [spain]: `/${es}/ortodoncia-infantil`,
        },

        error: "/404",
    },
};

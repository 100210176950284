import { useEffect, RefObject } from "react";

type EventType = MouseEvent | TouchEvent;

export const useClickOutside = (
    ref: RefObject<HTMLElement | undefined>,
    callback: () => void
) => {
    const handleClickOutside = (event: EventType) => {
        if (!ref.current || ref.current.contains(event.target as Element)) {
            return;
        }

        callback();
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
};

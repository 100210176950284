import { format, formatDuration } from "date-fns";

import { locales, LocaleKeyType } from "constants/enum";

export const getFullDate = (dateNow: Date) => {
    const date = ("0" + dateNow.getDate()).slice(-2);
    const month = ("0" + (dateNow.getMonth() + 1)).slice(-2);

    return `${date}.${month}.${dateNow.getFullYear()}`;
};

export const getTime = (dateNow: Date) => {
    const hours = ("0" + dateNow.getHours()).slice(-2);
    const minutes = ("0" + dateNow.getMinutes()).slice(-2);

    return { hours, minutes };
};

export const checkIfDateInFuture = (date?: Date) => {
    if (!date) {
        return null;
    }

    const dateNow = new Date().valueOf();
    const difference = date.valueOf() - dateNow;
    return difference > 0;
};

export const getCurrentYear = () => new Date().getFullYear();

export const formatDate = (
    date: Date,
    stringFormat: string,
    language: LocaleKeyType
) =>
    format(date, stringFormat, {
        locale: locales[language],
    });

export const formatDurationInMinutes = (
    minutes: number,
    language: LocaleKeyType
) => formatDuration({ minutes }, { locale: locales[language] });

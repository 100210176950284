import { useEffect, useState } from "react";

import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "constants/enum";
import { generateUuid } from "utils/generateUuid";

export const useAnalytics = () => {
    const [analyticData, setAnalyticData] = useState({});

    useEffect(() => {
        let vid = localStorage.getItem(LOCAL_STORAGE_KEYS.VID);
        let sessionId = sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_ID);

        if (!vid) {
            vid = generateUuid();
            localStorage.setItem(LOCAL_STORAGE_KEYS.VID, vid);
        }

        if (!sessionId) {
            sessionId = generateUuid();
            sessionStorage.setItem(SESSION_STORAGE_KEYS.SESSION_ID, sessionId);
        }

        setAnalyticData({ vid, sessionId });
    }, []);

    return analyticData;
};

import { FC, useEffect, useState, useCallback } from "react";
import { Box, Text, Flex } from "@smile2impress/components";

import {
    ThemeConfig,
    WINDOW_SIZE,
    CountryVersion,
    Language,
} from "constants/enum";
import { FlagIcon } from "components/Icons/FlagIcon";
import { noop } from "utils/noop";
import { RegionsLanguages } from "components/RegionsLanguages";
import { RegionsLanguagesMobile } from "components/RegionsLanguagesMobile";
import { LanguagesQuestion } from "components/LanguagesQuestion";
import { LanguagesQuestionMobile } from "components/LanguagesQuestionMobile";

import {
    changeCountry,
    changeLanguage,
    getTranslatedRegions,
    getTranslatedLanguages,
    getPreferredRegionCookie,
    getTranslatedCurrentRegion,
    checkIsQuestionAlreadySeen,
} from "./helpers";
import { RegionsAndLanguagesServiceProps } from "./RegionsAndLanguagesService.types";

const DEFAULT_DELAY = 1000;

const RegionsAndLanguagesService: FC<RegionsAndLanguagesServiceProps> = ({
    delay = DEFAULT_DELAY,
    content,
    version,
    isErrorPage,
    isMobileMenu,
    currentLanguage,
    hideRegionSelector,
    hideLanguageQuestion,
    onCloseMenu = noop,
}) => {
    const { menuItemRegion } = content;

    const regions = getTranslatedRegions(version as CountryVersion);
    const currentRegion = getTranslatedCurrentRegion(version as CountryVersion);
    const languages = getTranslatedLanguages(
        version as CountryVersion,
        currentLanguage as Language
    );
    const [isVisible, setVisibility] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isQuestionVisible, setQuestionVisible] = useState(false);

    const handleShowSwitcher = () => {
        setVisibility(true);
        document.body.classList.add("mobile-hidden");
    };

    const handleBackToMenu = () => {
        setVisibility(false);
    };

    const handleHideSwitcher = () => {
        setVisibility(false);
        setQuestionVisible(false);
        document.body.classList.remove("mobile-hidden");

        if (isMobileMenu) {
            onCloseMenu();
        }
    };

    const handleRedirectTo = useCallback((name: string) => {
        changeCountry(name);
    }, []);

    const handleChangeLanguage = useCallback((name: string) => {
        changeLanguage(name as Language, version as CountryVersion);
    }, []);

    const handleRememberRegion = useCallback(() => {
        handleHideSwitcher();
        getPreferredRegionCookie(
            version as CountryVersion,
            currentLanguage as Language
        );
    }, [isVisible]);

    const handleSwitchRegions = useCallback(() => {
        setQuestionVisible(false);
    }, [isVisible]);

    useEffect(() => {
        const questionNotSeenBefore = !checkIsQuestionAlreadySeen();
        setIsMobile(window.innerWidth < WINDOW_SIZE.TABLET);

        if (
            questionNotSeenBefore &&
            !isMobileMenu &&
            !isErrorPage &&
            !hideRegionSelector &&
            !hideLanguageQuestion
        ) {
            setTimeout(() => {
                handleShowSwitcher();
                setQuestionVisible(true);
            }, delay);
        }
    }, []);

    return (
        <>
            {isMobile ? (
                <>
                    {isMobileMenu && (
                        <Flex
                            as="button"
                            type="button"
                            pt={2}
                            pb={2}
                            border="none"
                            backgroundColor="transparent"
                            justifyContent="space-between"
                            alignItems="center"
                            w="100%"
                            onClick={handleShowSwitcher}
                        >
                            <Text textStyle="body">{menuItemRegion}</Text>
                            <Flex justifyContent="center" alignItems="center">
                                <Box mr={1} h={3} w={3}>
                                    <FlagIcon version={version} />
                                </Box>

                                <Text
                                    as="span"
                                    pl={1.5}
                                    borderLeft="0.5px solid rgba(66, 78, 88, 0.1)"
                                    textTransform="capitalize"
                                >
                                    {currentLanguage}
                                </Text>
                            </Flex>
                        </Flex>
                    )}

                    {isVisible && (
                        <Box
                            position={isQuestionVisible ? "fixed" : "absolute"}
                            w="100%"
                            h="100%"
                            top={0}
                            left={0}
                            zIndex="tooltip"
                        >
                            <Box
                                onClick={handleHideSwitcher}
                                position="fixed"
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                width="100vw"
                                height="100vh"
                            />
                            {isQuestionVisible ? (
                                <LanguagesQuestionMobile
                                    content={content}
                                    regions={regions}
                                    onRememberRegion={handleRememberRegion}
                                    onSwitchRegions={handleSwitchRegions}
                                    onRedirectTo={handleRedirectTo}
                                />
                            ) : (
                                <RegionsLanguagesMobile
                                    content={content}
                                    regions={regions}
                                    languages={languages}
                                    version={version}
                                    currentRegion={currentRegion}
                                    onRedirectTo={handleRedirectTo}
                                    onChangeLanguage={handleChangeLanguage}
                                    onBackToMenu={handleBackToMenu}
                                />
                            )}
                        </Box>
                    )}
                </>
            ) : (
                <Box position="relative" h="100%">
                    <Flex
                        position="relative"
                        height="100%"
                        width="100px"
                        ml={0}
                        mr={0}
                        alignItems="center"
                        justifyContent={[
                            "flex-start",
                            "flex-start",
                            "space-between",
                        ]}
                        pl={[0, 0, 2]}
                        pr={[0, 0, 2]}
                        backgroundColor="transparent"
                        cursor="pointer"
                        transition="background-color 0.3s"
                        onClick={handleShowSwitcher}
                        sx={{
                            "&::after": {
                                backgroundColor: ThemeConfig.linkColor,
                            },
                            "&::before": {
                                backgroundColor: ThemeConfig.linkActiveColor,
                            },
                        }}
                        _after={{
                            content: "''",
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            left: [4, 4, "52%"],
                            display: "block",
                            width: [2, 2, "1px"],
                            height: [2, 2, 3],
                            backgroundColor: [
                                "transparent",
                                "transparent",
                                "base.overlay",
                            ],
                        }}
                        _before={{
                            content: "''",
                            display: "block",
                            position: "absolute",
                            left: 0,
                            bottom: 0,
                            width: "100%",
                            height: "2px",
                            backgroundColor: "base.accent",
                            transform: "scaleX(0)",
                            transition: "transform 0.3s ease-in-out",
                        }}
                        _hover={{
                            _before: {
                                transform: "scaleX(1)",
                            },
                        }}
                    >
                        <Box overflow="hidden" h={3} borderRadius="50%">
                            <FlagIcon version={version} />
                        </Box>
                        <Text
                            textTransform="capitalize"
                            as="span"
                            display={["none", "none", "block"]}
                        >
                            {currentLanguage}
                        </Text>
                    </Flex>
                    {isVisible ? (
                        <>
                            <Box
                                position="fixed"
                                top="-10vh"
                                left="-10vw"
                                bottom="-10vh"
                                right="-10vw"
                                width="120vw"
                                height="120vh"
                                zIndex="overlay"
                                onClick={handleHideSwitcher}
                                display={["block", "block", "none"]}
                            />
                            {isQuestionVisible ? (
                                <LanguagesQuestion
                                    content={content}
                                    regions={regions}
                                    onRememberRegion={handleRememberRegion}
                                    onSwitchRegions={handleSwitchRegions}
                                    onRedirectTo={handleRedirectTo}
                                />
                            ) : (
                                <RegionsLanguages
                                    content={content}
                                    regions={regions}
                                    version={version as CountryVersion}
                                    languages={languages}
                                    currentRegion={currentRegion}
                                    onRedirectTo={handleRedirectTo}
                                    onChangeLanguage={handleChangeLanguage}
                                    onClose={handleHideSwitcher}
                                />
                            )}
                        </>
                    ) : null}
                </Box>
            )}
        </>
    );
};

export default RegionsAndLanguagesService;

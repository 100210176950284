import { CountryVersion } from "constants/enum";

export interface WhatsappWidgetProps {
    number: number | string;
    pre_filled_message: string;

    source?: SourceProps;
    hide?: boolean;
    version?: CountryVersion;
    className?: string;
}

export enum SourceProps {
    FROM_SITE = "from-site",
    FROM_LANDING = "from-landing",
}

import { FC } from "react";
import { Flex, Link, Picture } from "@smile2impress/components";

import { BadgetAward } from "components/BadgetAward";
import { Theme } from "constants/enum";

export type FooterBadgesProps = {
    theme?: any;
    trustpilot: { link: string };
};

export const FooterBadges: FC<FooterBadgesProps> = ({ trustpilot, theme }) => (
    <Flex justifyContent={["flex-start", "flex-end"]}>
        {trustpilot?.link && (
            <Link
                href={trustpilot.link}
                target="_blank"
                rel="noopener nofollow"
                mr={5}
            >
                <Picture
                    images={{
                        png: `/images/trustpilot-${
                            theme?.initialColorMode || Theme.LIGHT
                        }.png`,
                    }}
                    pictureProps={{ height: 6 }}
                />
            </Link>
        )}
        <BadgetAward />
    </Flex>
);

import { FunctionComponent, useState } from "react";
import {
    Button,
    Heading,
    Box,
    Flex,
    useTheme,
} from "@smile2impress/components";

import { Regions } from "components/Regions";
import { Theme } from "constants/enum";

import { LanguagesQuestionMobileProps } from "./LanguagesQuestionMobile.types";

const LanguagesQuestion: FunctionComponent<LanguagesQuestionMobileProps> = ({
    content,
    regions,
    onRedirectTo,
    onSwitchRegions,
    onRememberRegion,
}) => {
    const { initialColorMode } = useTheme();
    const { question, changeRegion, buttonTitle, regionTitle } = content;
    const [isRegionsActive, setIsRegionsActive] = useState(false);

    const handleSwitchRegions = () => {
        setIsRegionsActive(!isRegionsActive);
    };

    return (
        <Box minH="100vh">
            <Box
                backgroundColor={
                    initialColorMode === Theme.DARK
                        ? "base.spaceGrey"
                        : "base.primary"
                }
                display={isRegionsActive ? "none" : "block"}
                textAlign="center"
                position="absolute"
                width="calc(100% - 16px)"
                transform="translateX(-50%)"
                left="50%"
                pt={4}
                pb={4}
                zIndex={1}
                bottom={0}
            >
                <Heading as="h3" textStyle="title2" mb={3}>
                    {question}
                </Heading>
                <Flex flexDirection="column" alignItems="center">
                    <Button onClick={onRememberRegion} mb="1">
                        {buttonTitle}
                    </Button>
                    <Button
                        onClick={handleSwitchRegions}
                        size="sm"
                        variant="ghost"
                    >
                        {changeRegion}
                    </Button>
                </Flex>
            </Box>
            <Box
                position="absolute"
                top={7}
                left={0}
                right={0}
                bottom={0}
                backgroundColor={
                    initialColorMode === Theme.DARK
                        ? "base.spaceGrey"
                        : "base.primary"
                }
                borderRadius={2}
                transform={
                    isRegionsActive
                        ? "translate3d(0, 0, 0)"
                        : "translate3d(0, 100%, 0)"
                }
                transition="transform 0.5s"
            >
                <Heading px={3} pt={2} pb={1} textStyle="title">
                    {regionTitle}
                </Heading>
                <Regions
                    regions={regions}
                    onRedirectTo={onRedirectTo}
                    onSwitchRegions={onSwitchRegions}
                />
            </Box>
        </Box>
    );
};

export default LanguagesQuestion;

import { Box, Text } from "@smile2impress/components";
import { FC } from "react";

import { FooterLink, FooterLinkProps } from "./FooterLink";

export type FooterColumnProps = {
    title: string;
    links: FooterLinkProps[];
};

export const FooterColumn: FC<FooterColumnProps> = ({ title, links }) => (
    <Box>
        <Text textStyle="title4" color="text.secondary" mb={[2]}>
            {title}
        </Text>
        <div>
            {links.map((props, index) => (
                <FooterLink {...props} key={index} />
            ))}
        </div>
    </Box>
);

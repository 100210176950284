import { Translate } from "next-translate";

import { CountryVersion, ContactTypes, SocialTypes } from "constants/enum";
import contacts from "configs/contacts.json";
import { getWALink } from "utils/getWALink";
import { getCurrentYear } from "utils/date";
import { pathConfig as commonPathConfig } from "configs/pathConfig";
import { pathConfig } from "navigation/pathConfig";
import cookiesPolicyBanner from "content/cookiesPolicyBanner";
import { CommonPageContentProps } from "content/content.types";
import { PageContentProps } from "layouts/BaseLayout/BaseLayout.types";

import commonMenu from "./menu";

const languagesService = (t: Translate, version: CountryVersion) => ({
    regionTitle: t("global:regionTitle"),
    languageTitle: t("global:languageTitle"),
    buttonTitle: t("global:buttonAnswerYes"),
    question: t(`global:languageQuestion.${version}`),
    changeRegion: t("global:changeRegionAnswerNo"),
    menuItemRegion: t("global:menuItemRegion"),
});

const pageContent = (
    t: Translate,
    { version, language }: CommonPageContentProps
): PageContentProps => ({
    header: {
        logoLink: pathConfig.main[version],
        menu: [
            {
                id: "teen",
                name: t("global:teensPageTitle"),
                link: pathConfig.teen[version],
            },
            ...commonMenu(t, { version }),
        ],
        appointment: {
            name: t("global:bookAppointmentButton"),
            link: pathConfig.universalForm[version],
        },
        login: {
            name: t("global:loginButton"),
            link: `${commonPathConfig.login[version]}&lang=${language}`,
        },
    },
    contacts: {
        wa: {
            number: contacts.whatsapp[version].number,
            pre_filled_message: t("global:whatsappMessage"),
        },
    },
    languagesService: languagesService(t, version),
    footer: {
        company: {
            title: t("global:companyFooterText"),
            links: [
                {
                    id: "about",
                    name: t("global:whoWeArePageTitle"),
                    link: pathConfig.about[version],
                },
                {
                    id: "whyImpress",
                    name: t("global:whyImpressPageTitle"),
                    link: pathConfig.whyImpress[version],
                },
                {
                    id: "press",
                    name: t("global:pressPageTitle"),
                    link: pathConfig.pressReleases[version],
                },
                {
                    id: "careers",
                    name: t("global:careersPageTitle"),
                    link: "https://jobs.smile2impress.com/",
                    target: "_blank",
                },
                {
                    id: "blog",
                    name: t("global:blogPageTitle"),
                    link: pathConfig.article_common[version],
                    target: "_self",
                },
            ],
        },
        product: {
            title: t("global:productFooterText"),
            links: [
                {
                    id: "teens",
                    name: t("global:teensPageTitle"),
                    link: pathConfig.teen[version],
                },
                {
                    id: "results",
                    name: t("global:resultsPageTitle"),
                    link: pathConfig.results[version],
                },
                {
                    id: "locations",
                    name: t("global:locationPageTitle"),
                    link: pathConfig.locations[version],
                },
                {
                    id: "howItWorks",
                    name: t("global:howItWorksPageTitle"),
                    link: pathConfig.howItWorks[version],
                },
            ],
        },
        legalNotice: {
            title: t("global:legalNoticeFooterText"),
            links: [
                {
                    id: "privacyPolicy",
                    name: t("global:privacyPolicy"),
                    link: pathConfig.privacyPolicy[version]?.[language],
                },
                {
                    id: "cookiesPolicy",
                    name: t("global:cookiesPolicy"),
                    link: pathConfig.cookiesPolicy[version]?.[language],
                },
                {
                    id: "cookiesSettings",
                    name: t("global:cookiesSettings"),
                    cookiesSetting: true,
                    link: "",
                },
                {
                    id: "legalNotice",
                    name: t("global:legalNoticeFooterText"),
                    link: pathConfig.legalBasis[version]?.[language],
                },
            ],
        },
        support: {
            title: t("global:supportFooterText"),
            links: [
                {
                    id: ContactTypes.CHAT,
                    name: t("global:helpCenter"),
                    icon: SocialTypes.CHAT,
                    link: `${contacts.helpCenter[version].link}&lang=${language}`,
                },
                {
                    id: ContactTypes.PHONE,
                    name: contacts.phone[version].public,
                    icon: ContactTypes.PHONE,
                    link:
                        contacts.phone[version].link &&
                        `tel:${contacts.phone[version].link}`,
                },
                {
                    id: SocialTypes.WA,
                    name: "WhatsApp",
                    icon: SocialTypes.WA,
                    link: getWALink(
                        contacts.whatsapp[version].link,
                        t("global:whatsappMessage")
                    ),
                },
                {
                    id: SocialTypes.TG,
                    name: "Telegram",
                    icon: SocialTypes.TG,
                    link: contacts.telegram[version].link,
                },
                {
                    id: ContactTypes.EMAIL,
                    name: t("global:email"),
                    icon: ContactTypes.EMAIL,
                    link:
                        contacts.email[version].link &&
                        `mailto:${contacts.email[version].link}`,
                },
            ],
        },
        workingHours: {
            weekdays: t(`global:workingHours.weekdays.${version}`),
            weekends: t(`global:workingHours.weekends.${version}`),
        },
        copyright: {
            text: `© ${getCurrentYear()} SMILE2IMPRESS SL. ${t(
                "global:copyright"
            )}`,
        },
        socialNetworks: [
            {
                iconName: SocialTypes.INSTAGRAM,
                link: contacts.instagram[version].link,
            },
            {
                iconName: SocialTypes.FB,
                link: contacts.facebook[version].link,
            },
            {
                iconName: SocialTypes.LINKDIN,
                link: contacts.linkdin[version].link,
            },
            {
                iconName: SocialTypes.TIKTOK,
                link: contacts.tiktok[version].link,
            },
        ],
        badges: {
            trustpilot: {
                link: contacts.trustpilot[version].link,
            },
        },
    },
    cookiesPolicy: cookiesPolicyBanner[version](t, { version, language }),
});

export default pageContent;

import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { CommonPageContentProps } from "content/content.types";
import { pathConfig } from "navigation/pathConfig";

import defaultContent from "./common";

const pageContent = (
    t: Translate,
    { version, language }: CommonPageContentProps
) => {
    const content = defaultContent(t, { version, language });

    return mergeDeepRight(content, {
        footer: {
            hideBadges: true,
            legalNotice: {
                links: [
                    {
                        id: "privacyPolicy",
                        name: t("global:privacyPolicy"),
                        link: pathConfig.privacyPolicy[version]?.[language],
                        target: "_blank",
                    },
                    {
                        id: "impressum",
                        name: t("global:impressum"),
                        link: pathConfig.impressum[version],
                        target: "_blank",
                    },
                ],
            },
        },
    });
};

export default pageContent;

import React, { FC } from "react";
import useTranslation from "next-translate/useTranslation";
import Head from "next/head";

import { MetaTagsProps } from "./MetaTags.types";

export const MetaTags: FC<MetaTagsProps> = ({
    title,
    version,
    pageName,
    description,
}) => {
    const { t } = useTranslation("meta");

    const metaTitle =
        title || t(`${pageName}Title.${version}`, {}, { fallback: "" });
    const metaDescription =
        description ||
        t(`${pageName}Description.${version}`, {}, { fallback: "" });

    return (
        <Head>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
        </Head>
    );
};

import { AxiosError } from "axios";
import * as Sentry from "@sentry/nextjs";

export const handleApiError = (error: AxiosError) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.warn(error.response.data);
        console.warn(error.response.status);
        console.warn(error.response.headers);

        const isInnerError =
            error?.response?.config?.baseURL === process.env.API_URL_V2;

        if (isInnerError) {
            Sentry.captureException(JSON.stringify(error.response));
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.warn(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.warn("Error", error.message);
    }

    return error.response?.data;
};

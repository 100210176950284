import { Box, Flex, Link, Text } from "@smile2impress/components";
import { FC } from "react";

import { useCookiesPolicyContext } from "context/cookiesPolicyContext";
import {
    SocialIconNameType,
    SocialMediaIcon,
} from "components/Icons/SocialMediaIcon";

export type FooterLinkProps = {
    link: string;
    name: string;

    target?: string;
    id?: string;
    icon?: SocialIconNameType;
    cookiesSetting?: boolean;
};

export const FooterLink: FC<FooterLinkProps> = ({
    link,
    name,
    icon,
    target = "_self",
    cookiesSetting = false,
}) => {
    const { setIsCookiesPopupVisible } = useCookiesPolicyContext();

    const onCookiesClick = () => {
        setIsCookiesPopupVisible(true);
    };

    if (!link || !name) {
        return null;
    }

    return link || cookiesSetting ? (
        <Flex
            as={cookiesSetting ? Box : Link}
            href={link}
            target={target}
            alignItems="center"
            cursor="pointer"
            {...(cookiesSetting ? { onClick: onCookiesClick } : {})}
        >
            {icon && (
                <Box mr={2} py={[1]} maxHeight={[5]}>
                    <SocialMediaIcon iconName={icon} />
                </Box>
            )}
            <Text textStyle="caption" py={[1]}>
                {name}
            </Text>
        </Flex>
    ) : null;
};

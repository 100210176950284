import { Translate } from "next-translate";

import { versions } from "configs/versionsConfig";
import { CommonPageContentProps } from "content/content.types";

import france from "../../content/common/france";
import germany from "../../content/common/germany";
import italy from "../../content/common/italy";
import portugal from "../../content/common/portugal";
import spain from "../../content/common/spain";
import uk from "../../content/common/uk";
import ukraine from "../../content/common/ukraine";
import us from "../../content/common/us";

interface Dictionary {
    [key: string]: (t: Translate, { version }: CommonPageContentProps) => any;
}

export const dictionary: Dictionary = {
    [versions.france]: france,
    [versions.germany]: germany,
    [versions.italy]: italy,
    [versions.portugal]: portugal,
    [versions.spain]: spain,
    [versions.uk]: uk,
    [versions.ukraine]: ukraine,
    [versions.us]: us,
};

export { default as BaseLayout } from "./BaseLayout";

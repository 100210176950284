import { FunctionComponent, useState, useEffect, useRef } from "react";
import { Flex, Box, Link, Text } from "@smile2impress/components";

import { useWindowDimensions } from "hooks/useWindowDimensions";

import { Switcher } from "../Switcher";
import { Table } from "../Table";
import { CookiesListMobileProps } from "./CookiesListMobile.types";

export const CookiesListMobile: FunctionComponent<CookiesListMobileProps> = (
    props
) => {
    const { content = [], headerHeight, footerHeight } = props;
    const { height } = useWindowDimensions();

    const [isActivePoint, setIsActivePoint] = useState(0);
    const [menuHeight, setMenuHeight] = useState(0);
    const menu = useRef<HTMLDivElement>(null);

    const handleChoosePoint = (index: number) => {
        setIsActivePoint(index);
    };

    const contentHeight =
        height - menuHeight - headerHeight - footerHeight + 25;

    useEffect(() => {
        const menuBlockHeight = menu?.current?.clientHeight || 0;

        setMenuHeight(menuBlockHeight);
    }, []);

    return (
        <Flex flexDir="column" h="100%">
            <Box overflow="hidden">
                <Box overflowX="scroll" mb={-3} ref={menu}>
                    <Flex p={3}>
                        {content.map((item, index: number) => {
                            const activePoint = index === isActivePoint;
                            const { name } = item;

                            return (
                                <Text
                                    position="relative"
                                    pr={3}
                                    pb={2}
                                    cursor="pointer"
                                    textStyle="body"
                                    {...(activePoint && {
                                        color: "text.accent",
                                        _after: {
                                            content: "''",
                                            position: "absolute",
                                            left: 0,
                                            right: 3,
                                            bottom: 0,
                                            height: "4px",

                                            backgroundColor: "base.accent",
                                            borderRadius: "1px",
                                        },
                                    })}
                                    onClick={() => handleChoosePoint(index)}
                                    key={name}
                                >
                                    {name}
                                </Text>
                            );
                        })}
                    </Flex>
                </Box>
            </Box>
            <Box
                overflowY="scroll"
                pt={2}
                pr={3}
                pb={3}
                pl={3}
                style={{ height: contentHeight }}
            >
                {content.map((item, index: number) => {
                    const {
                        link,
                        status,
                        content,
                        switcher,
                        listTitles,
                        listCookies,
                    } = item;
                    const activePoint = index === isActivePoint;

                    return activePoint ? (
                        <div key={content}>
                            {status && (
                                <Text
                                    display="inline-block"
                                    textStyle="body"
                                    mb={2}
                                    borderRadius="lg"
                                    py={1}
                                    px={2}
                                >
                                    {status}
                                </Text>
                            )}
                            {switcher && <Switcher {...switcher} />}
                            <Text textStyle="body">{content}</Text>
                            {link?.href && link?.name && (
                                <Link href={link.href}>
                                    <Text textStyle="action" mt={2}>
                                        {link.name}
                                    </Text>
                                </Link>
                            )}
                            {listCookies && listTitles && (
                                <Table
                                    listTitles={listTitles}
                                    listCookies={listCookies}
                                />
                            )}
                        </div>
                    ) : null;
                })}
            </Box>
        </Flex>
    );
};

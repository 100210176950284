import { useEffect, useState } from "react";

const DELAY = 1000;

export const useCountdown = () => {
    const [countdownValue, setCountdownValue] = useState<number>(0);

    useEffect(() => {
        if (countdownValue) {
            const timer = setInterval(() => {
                if (countdownValue > 0) {
                    setCountdownValue(countdownValue - 1);
                } else {
                    setCountdownValue(0);
                }
            }, DELAY);

            return () => clearInterval(timer);
        }
    }, [countdownValue]);

    return { countdownValue, setCountdownValue };
};

import axios from "axios";
import { sha256 } from "js-sha256";

import { handleApiError } from "api/helpers";

import { PostFacebookEventType } from "./facebook.types";

const pixelAccessToken = process.env.PIXEL_ACCESS_TOKEN;
const pixelId = process.env.PIXEL_ID;

export const postFacebookEvent = async ({
    eventName,
    eventId,
    eventSourceUrl,
    userData,
}: PostFacebookEventType) =>
    await axios
        .post(
            `https://graph.facebook.com/v15.0/${pixelId}/events?access_token=${pixelAccessToken}`,
            {
                data: [
                    {
                        event_name: eventName,
                        event_time: Math.round(new Date().getTime() / 1000),
                        event_id: eventId,
                        ...(!!userData && {
                            user_data: {
                                ...(userData.client_user_agent && {
                                    client_user_agent:
                                        userData.client_user_agent,
                                    client_ip_address: "1.1.1.1",
                                }),
                                ...(userData.country && {
                                    country: sha256(userData.country),
                                }),
                                ...(userData.city && {
                                    ct: sha256(userData.city.toLowerCase()),
                                }),
                                ...(userData.firstName && {
                                    fn: sha256(
                                        userData.firstName.toLowerCase()
                                    ),
                                }),
                                ...(userData.lastName && {
                                    ln: sha256(userData.lastName.toLowerCase()),
                                }),
                                ...(userData.phone && {
                                    ph: sha256(
                                        userData.phone.replace(/\+/g, "")
                                    ),
                                }),
                                ...(userData.email && {
                                    em: sha256(userData.email.toLowerCase()),
                                }),
                                ...(userData.fbp && {
                                    fbp: userData.fbp,
                                }),
                                ...(userData.fbc && {
                                    fbc: userData.fbc,
                                }),
                            },
                        }),
                    },
                ],
                event_source_url: eventSourceUrl,
            }
        )
        .then((response) => {
            return response;
        })
        .catch(handleApiError);

import { languages } from "configs/languageConfig";

import france from "./france";
import italy from "./italy";
import portugal from "./portugal";
import spain from "./spain";
import english from "./english";
import germany from "./germany";
import ukraine from "./ukraine";
import cat from "./cat";

export default {
    [languages.en]: english,
    [languages.it]: italy,
    [languages.es]: spain,
    [languages.pt]: portugal,
    [languages.fr]: france,
    [languages.de]: germany,
    [languages.uk]: ukraine,
    [languages.ca]: cat,
};

import { Text } from "@smile2impress/components";
import { FC } from "react";

export type FooterCopyrightProps = {
    text: string;
};

export const FooterCopyright: FC<FooterCopyrightProps> = ({ text }) => (
    <Text textStyle="caption" color="text.secondary">
        {text}
    </Text>
);

import { Flex, Link } from "@smile2impress/components";
import { FC } from "react";

import {
    SocialIconNameType,
    SocialMediaIcon,
} from "components/Icons/SocialMediaIcon";

export type SocialNetworkType = {
    iconName: SocialIconNameType;
    link: string;
};

type SocialNetworkProps = {
    socialNetworks: SocialNetworkType[];
};

export const FooterSocial: FC<SocialNetworkProps> = ({ socialNetworks }) => (
    <Flex>
        {socialNetworks.map(({ iconName, link }) => (
            <Link
                mr={[2]}
                key={iconName}
                href={link}
                rel="noopener nofollow"
                stroke="text.primary"
            >
                <SocialMediaIcon iconName={iconName} />
            </Link>
        ))}
    </Flex>
);

import React, { FC } from "react";
import classNames from "classnames/bind";
import { CountryVersion } from "constants/enum";

import {
    TelegramSourceProps,
    TelegramWidgetProps,
} from "./TelegramWidget.types";
import styles from "./TelegramWidget.module.scss";

const cx = classNames.bind(styles);

export const TelegramWidget: FC<TelegramWidgetProps> = ({
    href,
    className,
    hide,
    version,
    source = TelegramSourceProps.FROM_SITE,
}) => {
    return (
        <div
            className={cx(
                "root",
                `root_source-${source}`,
                className,
                hide && "hide",
                {
                    "root_version-germany": version === CountryVersion.GERMANY,
                }
            )}
        >
            <a
                className={cx("telegram-link")}
                href={href}
                target="_blank"
                rel="noreferrer"
            />
        </div>
    );
};

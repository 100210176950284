import { FunctionComponent, useState } from "react";
import { Flex, Box, Text, Link } from "@smile2impress/components";

import { Switcher } from "../Switcher";
import { Table } from "../Table";
import { CookiesListProps } from "./CookiesList.types";

export const CookiesList: FunctionComponent<CookiesListProps> = ({
    content = [],
}) => {
    const [isActivePoint, setIsActivePoint] = useState(0);

    const handleChoosePoint = (index: number) => {
        setIsActivePoint(index);
    };

    return (
        <Flex
            h={["100%", "100%", "auto"]}
            overflowY={["auto", "auto", "visible"]}
            flexDir={["column", "column", "row"]}
        >
            <Box w={["100%", "100%", "34%"]} pt={3} pr={3} pb={5} pl={3}>
                {content.map((item, index: number) => {
                    const activePoint = index === isActivePoint;
                    const { name } = item;

                    return (
                        <Text
                            textStyle="caption"
                            pl={4}
                            pr={2}
                            mb={2}
                            cursor="pointer"
                            position="relative"
                            {...(activePoint && {
                                color: "text.accent",
                                _after: {
                                    content: "''",
                                    position: "absolute",
                                    top: 0,
                                    left: "-4px",
                                    bottom: 0,
                                    height: "100%",
                                    width: "4px",
                                    backgroundColor: "base.accent",
                                    borderRadius: "1px",
                                },
                            })}
                            onClick={() => handleChoosePoint(index)}
                            key={name}
                        >
                            {name}
                        </Text>
                    );
                })}
            </Box>
            <Box
                pt={3}
                pr={5}
                pb={5}
                pl={3}
                overflowY="scroll"
                w={["100%", "100%", "66%"]}
                minH={["auto", "auto", "364px"]}
                maxH={["none", "none", "364px"]}
            >
                {content.map((item, index: number) => {
                    const {
                        link,
                        status,
                        content,
                        switcher,
                        listTitles,
                        listCookies,
                    } = item;
                    const activePoint = index === isActivePoint;

                    return activePoint ? (
                        <div key={content}>
                            {status && (
                                <div>
                                    <Text
                                        mb={2}
                                        py={1}
                                        px={2}
                                        borderRadius="lg"
                                        textStyle="caption"
                                    >
                                        {status}
                                    </Text>
                                </div>
                            )}
                            {switcher && <Switcher {...switcher} />}
                            <Text textStyle="caption">{content}</Text>
                            {link?.href && link?.name && (
                                <Link href={link.href}>
                                    <Text textStyle="action" mt={2}>
                                        {link.name}
                                    </Text>
                                </Link>
                            )}
                            {listCookies && listTitles && (
                                <Table
                                    listTitles={listTitles}
                                    listCookies={listCookies}
                                />
                            )}
                        </div>
                    ) : null;
                })}
            </Box>
        </Flex>
    );
};

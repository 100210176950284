import { regions } from "configs/regionsConfig";
import { CountrySlugs } from "constants/enum";
import { versions } from "configs/versions";

import { urlsConfig } from "./urlsConfig";

const { spain, italy, portugal, uk, france, germany, ukraine, us } = versions;
const { fr, it, pt, es } = regions;
const personalAreaLoginLink = `${urlsConfig.personalArea}/login`;

export const pathConfig = {
    universalForm: {
        [france]: `/${fr}/universal-form/`,
        [germany]: "/de/universal-form/",
        [italy]: `/${it}/universal-form/`,
        [portugal]: `/${pt}/universal-form/`,
        [spain]: `/${es}/universal-form/`,
        [uk]: `/${uk}/universal-form/`,
        [ukraine]: "/ua/universal-form/",
        [us]: "/us/universal-form",
    },
    universalSelect: {
        [france]: `/${fr}/universal-select/`,
        [italy]: `/${it}/universal-select/`,
        [portugal]: `/${pt}/universal-select/`,
        [spain]: `/${es}/universal-select/`,
        [uk]: `/${uk}/universal-select/`,
        [us]: `/${us}/universal-select/`,
    },
    clinicPage: {
        [france]: `/${fr}/page-clinique/`,
        [italy]: `/${it}/clinica-ortodonzia-invisibile/`,
        [portugal]: `/${pt}/clinica-ortontica-invisivel/`,
        [spain]: `/${es}/clinica-ortodoncia-invisible/`,
        [uk]: `/${uk}/clinic-page/`,
        [us]: `/${us}/orthodontic-clinics`,
    },
    login: {
        [france]: `${personalAreaLoginLink}?country=${CountrySlugs.france}`,
        [italy]: `${personalAreaLoginLink}?country=${CountrySlugs.italy}`,
        [portugal]: `${personalAreaLoginLink}?country=${CountrySlugs.portugal}`,
        [spain]: `${personalAreaLoginLink}?country=${CountrySlugs.spain}`,
        [uk]: `${personalAreaLoginLink}?country=${CountrySlugs.uk}`,
        [ukraine]: `${personalAreaLoginLink}?country=${CountrySlugs.ukraine}`,
        [germany]: `${personalAreaLoginLink}?country=${CountrySlugs.germany}`,
        [us]: `${personalAreaLoginLink}?country=${CountrySlugs.us}`,
    },
    main: {
        [france]: `/${fr}`,
        [germany]: "/de",
        [italy]: `/${it}`,
        [portugal]: `/${pt}`,
        [spain]: `/${es}`,
        [uk]: `/${uk}`,
        [ukraine]: "/ua",
        [us]: "/us",
    },
};

module.exports = {
    regions: {
        it: "it",
        es: "es",
        pt: "pt",
        fr: "fr",
        en: "en",
        ca: "ca",
        ch: "ch",
        gb: "gb",
        de: "de",
        ua: "ua",
        us: "us",
    },
};

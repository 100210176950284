import { FC, useState, useEffect } from "react";
import classNames from "classnames/bind";
import { useRouter } from "next/router";
import { nanoid } from "nanoid";
import { mergeDeepRight } from "ramda";
import { sha256 } from "js-sha256";
import useTranslation from "next-translate/useTranslation";
import { defaultTheme } from "@smile2impress/themes";
import { Box, ThemeProvider } from "@smile2impress/components";

import { LocationContext } from "context/locationContext";
import { WhatsappWidget } from "components/WhatsappWidget";
import { TelegramWidget } from "components/TelegramWidget";
import {
    SHOW_COOKIES_BANNER_EXCEPTIONS,
    SHOW_LANGUAGE_QUESTION_EXCEPTIONS,
    PAGES_WITHOUT_FIX_HEADER,
    HIDE_SOCIAL_WIDGET,
} from "constants/enum";
import { ExperimentProvider } from "providers/ExperimentProvider";
import { CookiesPolicyContextProvider } from "context/cookiesPolicyContext";
import { CountdownContextProvider } from "context/countdownContext";
import { postFacebookEvent } from "api/facebook";
import { getCookiesValueByName } from "utils/cookie";
import { useAnalytics } from "hooks/useAnalytics";
import { useCountdown } from "hooks/useCountdown";
import { COOKIES, WINDOW_SIZE } from "constants/enum";
import {
    FacebookEvents,
    SnapchatPixelEvents,
} from "services/AnaliticsService/events";
import { sendFacebookEvent } from "services/AnaliticsService/sendFacebookAnalitic";
import { sendSnapchatPixelEvent } from "services/AnaliticsService/sendSnapchatAnalitic";
import { MetaTags } from "components/MetaTags";
import { RegionsAndLanguagesService } from "services/RegionsAndLanguagesService";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { CookiesPolicy } from "components/CookiesPolicy/CookiesPolicy";

import { dictionary } from "./index";
import { BaseLayoutProps } from "./BaseLayout.types";
import styles from "./BaseLayout.module.scss";

const cx = classNames.bind(styles);

const BaseLayout: FC<BaseLayoutProps> = ({
    children,
    theme = defaultTheme,
    hideMenu = false,
    hideRegionSelector = false,
    withHeader = true,
    withFooter = true,
    isMainPage,
    isErrorPage = false,
    currentPage,
    layout = {},
    version,
    language,
    seoTitle,
    seoDescription,
    experimentCountries = [],
}) => {
    const hasWindow = typeof window !== "undefined";
    const router = useRouter();
    const { t } = useTranslation();
    useAnalytics();
    const { countdownValue, setCountdownValue } = useCountdown();

    const hideCookiesPolicyBanner = SHOW_COOKIES_BANNER_EXCEPTIONS.includes(
        router?.pathname
    );

    const hideLanguageQuestion = SHOW_LANGUAGE_QUESTION_EXCEPTIONS.includes(
        router?.pathname
    );

    const isCurrentPageWithoutFix = PAGES_WITHOUT_FIX_HEADER.includes(
        router?.pathname
    );

    const hideSocialWidget = HIDE_SOCIAL_WIDGET.includes(router?.pathname);

    const [isMobile, setIsMobile] = useState(false);

    const [isCookiesPopupVisible, setIsCookiesPopupVisible] = useState(false);
    const [isCookiesBannerVisible, setIsCookiesBannerVisible] = useState(false);

    useEffect(() => {
        setIsMobile(window.innerWidth < WINDOW_SIZE.TABLET);
    }, []);

    useEffect(() => {
        if (!hasWindow) {
            return;
        }

        const email = decodeURIComponent(getCookiesValueByName(COOKIES.EMAIL));
        const phone = decodeURIComponent(getCookiesValueByName(COOKIES.PHONE));

        sendSnapchatPixelEvent(
            SnapchatPixelEvents.SNAPCHAT_PIXEL_PAGE_VIEW_EVENT,
            {
                ...(email && {
                    user_email: email,
                    user_hashed_email: sha256(email),
                }),
                ...(phone && {
                    user_phone_number: phone,
                    user_hashed_phone_number: sha256(phone),
                }),
            }
        );

        // Events fire
        const eventID = nanoid();

        sendFacebookEvent(
            FacebookEvents.FORM_SUBMIT_FACEBOOK_PAGE_VIEW_EVENT,
            {},
            {
                eventID,
            }
        );

        const sendConversionApiEvent = async () => {
            const fbp = decodeURIComponent(getCookiesValueByName(COOKIES.FBP)),
                fbc = decodeURIComponent(getCookiesValueByName(COOKIES.FBC)),
                ua = window?.navigator?.userAgent;
            await postFacebookEvent({
                eventName: FacebookEvents.FORM_SUBMIT_FACEBOOK_PAGE_VIEW_EVENT,
                eventSourceUrl: location.href,
                eventId: eventID,
                ...((fbp || fbc || ua) && {
                    userData: {
                        fbp,
                        fbc,
                        client_user_agent: ua,
                    },
                }),
            });
        };

        sendConversionApiEvent();
    }, [hasWindow]);

    const {
        languagesService: contentLanguagesService,
        contacts: { wa, tg },
        header,
        footer,
        cookiesPolicy,
    } = mergeDeepRight(dictionary[version](t, { version, language }), layout);
    const languagesService = {
        version,
        isErrorPage,
        currentLanguage: language,
        content: contentLanguagesService,
        hideRegionSelector: hideRegionSelector,
    };

    const fullHeaderContent = {
        ...header,
        currentPage,
        isErrorPage,
        languagesService,
    };

    const fullFooterContent = {
        ...footer,
        isErrorPage,
        theme,
        version,
    };

    const showHeader = withHeader && fullHeaderContent.withHeader !== false;
    const showFooter = withFooter && fullFooterContent.withFooter !== false;

    const pageName = router?.pathname?.slice(1);

    return (
        <ThemeProvider theme={theme}>
            <ExperimentProvider
                version={version}
                experimentCountries={experimentCountries}
            >
                <CountdownContextProvider
                    value={{ countdownValue, setCountdownValue }}
                >
                    <LocationContext.Provider value={{ version, language }}>
                        <CookiesPolicyContextProvider
                            value={{
                                isCookiesPopupVisible,
                                isCookiesBannerVisible,
                                setIsCookiesPopupVisible,
                                setIsCookiesBannerVisible,
                            }}
                        >
                            <Box
                                className={cx("root", `root_${version}`)}
                                backgroundColor="base.primary"
                                overflowY="hidden"
                            >
                                <MetaTags
                                    pageName={pageName}
                                    title={seoTitle}
                                    version={version}
                                    description={seoDescription}
                                />
                                {showHeader && (
                                    <Header
                                        {...fullHeaderContent}
                                        theme={theme}
                                        isMainPage={isMainPage}
                                        hideMenu={hideMenu}
                                        version={version}
                                        hideLanguageQuestion={
                                            hideLanguageQuestion
                                        }
                                        isCurrentPageWithoutFix={
                                            isCurrentPageWithoutFix
                                        }
                                    />
                                )}
                                {children}
                                {showFooter && (
                                    <Box pt={5}>
                                        <Footer {...fullFooterContent} />
                                    </Box>
                                )}
                                <CookiesPolicy
                                    {...cookiesPolicy}
                                    hideCookiesPolicyBanner={
                                        hideCookiesPolicyBanner
                                    }
                                />
                                <div className={cx("question")}>
                                    {isMobile &&
                                        !languagesService.hideRegionSelector && (
                                            <RegionsAndLanguagesService
                                                {...languagesService}
                                                hideLanguageQuestion={
                                                    hideLanguageQuestion
                                                }
                                            />
                                        )}
                                </div>
                                {wa && !hideSocialWidget && (
                                    <WhatsappWidget {...wa} />
                                )}
                                {tg && !hideSocialWidget && (
                                    <TelegramWidget {...tg} version={version} />
                                )}
                            </Box>
                        </CookiesPolicyContextProvider>
                    </LocationContext.Provider>
                </CountdownContextProvider>
            </ExperimentProvider>
        </ThemeProvider>
    );
};

export default BaseLayout;

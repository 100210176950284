export enum FacebookEvents {
    FORM_SUBMIT_FACEBOOK_LEAD_EVENT = "Lead",
    FORM_SUBMIT_FACEBOOK_SCHEDULED_EVENT = "Schedule",
    FORM_SUBMIT_FACEBOOK_PAGE_VIEW_EVENT = "PageView",
}

export enum GTMEvents {
    FORM_SUBMIT_GTM_EVENT = "gtm.formSubmit",
    WIDGET_VIEWED_GTM_EVENT = "gtm.widgetViewed",
    WIDGET_DATE_TIME_SELECTED_GTM_EVENT = "gtm.widgetDateTimeSelected",
    WIDGET_BOOKING_SUBMITTED_GTM_EVENT = "gtm.widgetBookingSubmitted",
    WIDGET_BOOKING_RESCHEDULED_GTM_EVENT = "gtm.widgetBookingRescheduled",
    UNIVERSAL_LANDING_IN_EXPERIMENT_GTM_EVENT = "gtm.universalLandingInExperiment",
    CHANGE_COUNTRY_CODE_GTM_EVENT = "gtm.changeCountryCode",
    CHANGE_INPUT_COUNTRY_CODE_GTM_EVENT = "gtm.changeInputCountryCode",
}

export enum SnapchatPixelEvents {
    SNAPCHAT_PIXEL_PAGE_VIEW_EVENT = "PAGE_VIEW",
    SNAPCHAT_PIXEL_LEAD_EVENT = "ADD_CART",
    SNAPCHAT_PIXEL_SCHEDULED_EVENT = "PURCHASE",
}

export enum TikTokEvents {
    BOOKING_SUCCESS = "bookingSuccess",
}

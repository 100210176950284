import { ReactNode } from "react";

import { CountryVersion, LogoTheme } from "constants/enum";

export interface LogoProps {
    version: CountryVersion;

    logoTheme?: LogoTheme;
    logoColorTheme?: LogoColorTheme;
    isTeens?: boolean;
    hasMainLogo?: boolean;
    specialAdditionalLogo?: ReactNode;
    hasAdditionalLogo?: boolean;
    isDesktopVersionOnly?: boolean;
}

export enum LogoColorTheme {
    TEXT_PRIMARY = "text.primary",
    BASE_PRIMARY = "base.primary",
    ACTION_PRIMARY2 = "action.primary2",
}

import { FC } from "react";
import {
    Icon,
    EsIcon,
    FrIcon,
    ItIcon,
    PtIcon,
    GbIcon,
    UaIcon,
    DeIcon,
    UsIcon,
} from "@smile2impress/components";

import { CountryVersion } from "constants/enum";

export const FlagIcon: FC<{
    version: CountryVersion | "united-kingdom" | "united-states";
}> = ({ version }) => {
    const getIcon = (FlagIconComponent: any) => (
        <Icon as={FlagIconComponent} w={24} h={24} />
    );

    switch (version) {
        case CountryVersion.SPAIN:
        case CountryVersion.CATALAN:
            return getIcon(EsIcon);
        case CountryVersion.FRANCE:
            return getIcon(FrIcon);
        case CountryVersion.ITALY:
            return getIcon(ItIcon);
        case CountryVersion.PORTUGAL:
            return getIcon(PtIcon);
        case CountryVersion.UK:
        case "united-kingdom":
            return getIcon(GbIcon);
        case CountryVersion.UKRAINE:
            return getIcon(UaIcon);
        case CountryVersion.GERMANY:
            return getIcon(DeIcon);
        case CountryVersion.US:
        case "united-states":
            return getIcon(UsIcon);
        default:
            return null;
    }
};

import { sha256 } from "js-sha256";

import { SnapchatPixelEvents } from "./events";
import { AnaliticData } from "./types";

export const sendSnapchatPixelEvent = (
    eventName: string,
    eventData?: { [key: string]: string }
) => {
    if (!window.snaptr) {
        return;
    }

    window.snaptr("track", eventName, eventData);
};

export const sendAllSnapchatAnalitic = ({
    email,
    phone,
}: Pick<AnaliticData, "email" | "phone">) => {
    sendSnapchatPixelEvent(SnapchatPixelEvents.SNAPCHAT_PIXEL_LEAD_EVENT, {
        user_email: email,
        user_hashed_email: sha256(email),
        user_phone_number: phone,
        user_hashed_phone_number: sha256(phone),
    });
};

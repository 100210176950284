import { useContext, createContext } from "react";

const CookiesPolicyContext = createContext({
    isCookiesPopupVisible: false,
    setIsCookiesPopupVisible: (_value: boolean) => {},
    isCookiesBannerVisible: false,
    setIsCookiesBannerVisible: (_value: boolean) => {},
});

export const CookiesPolicyContextProvider = CookiesPolicyContext.Provider;

export const useCookiesPolicyContext = () => useContext(CookiesPolicyContext);

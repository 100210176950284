import { forwardRef } from "@chakra-ui/react";
import {
    Icon,
    CheckmarkThinOutlineIcon,
    Box,
    Flex,
    Text,
    useTheme,
} from "@smile2impress/components";

import { CountryVersion, Theme } from "constants/enum";
import { FlagIcon } from "components/Icons/FlagIcon";

import { RegionsProps, RegionType } from "./Regions.types";

const Regions = forwardRef<RegionsProps, "ul">(
    ({ regions, onSwitchRegions, onRedirectTo }, ref) => {
        const { initialColorMode } = useTheme();
        return (
            <Box
                as="ul"
                width="100%"
                backgroundColor={
                    initialColorMode === Theme.DARK
                        ? "base.spaceGrey"
                        : "base.primary"
                }
                listStyleType="none"
                borderRadius={2}
                pb={2}
                pl={[0, 0, 3]}
                ref={ref}
            >
                {regions.map(({ id, name, isActive }: RegionType) => {
                    const handleHideOrRedirect = () => {
                        if (isActive) {
                            onSwitchRegions();
                        } else {
                            onRedirectTo(id);
                        }
                    };

                    const countryId = id.replace(" ", "-").toLowerCase();

                    return (
                        <Flex
                            as="li"
                            justifyContent="space-between"
                            alignItems="center"
                            pt={2}
                            pb={2}
                            pl={[3, 3, 0]}
                            pr={[3]}
                            position="relative"
                            cursor="pointer"
                            key={id}
                            onClick={handleHideOrRedirect}
                        >
                            <Flex alignItems="center">
                                <Box w={3} h={3} mr={2}>
                                    <FlagIcon
                                        version={countryId as CountryVersion}
                                    />
                                </Box>

                                <Text as="span">{name}</Text>
                            </Flex>
                            {isActive && <Icon as={CheckmarkThinOutlineIcon} />}
                        </Flex>
                    );
                })}
            </Box>
        );
    }
);

Regions.displayName = "Regions";

export default Regions;

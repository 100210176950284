import { FunctionComponent, useRef, useState, useEffect } from "react";
import {
    Box,
    Button,
    Heading,
    Text,
    useTheme,
} from "@smile2impress/components";

import { Regions } from "components/Regions";
import { Theme } from "constants/enum";

import { LanguagesQuestionProps } from "./LanguagesQuestion.types";

const DELAY = 500;

const LanguagesQuestion: FunctionComponent<LanguagesQuestionProps> = ({
    content,
    regions,
    onRedirectTo,
    onSwitchRegions,
    onRememberRegion,
}) => {
    const { initialColorMode } = useTheme();
    const { question, changeRegion, buttonTitle, regionTitle } = content;

    const [isRegionsActive, setIsRegionsActive] = useState(false);
    const [isQuestionHide, setIsQuestionHide] = useState(false);
    const [regionsHeight, setRegionsHeight] = useState(0);
    const [questionHeight, setQuestionHeight] = useState(0);

    const regionsBlock = useRef<HTMLDivElement>(null);
    const questionBlock = useRef<HTMLDivElement>(null);

    const height = isRegionsActive ? regionsHeight : questionHeight;

    const handleSwitchRegions = () => {
        setIsRegionsActive(!isRegionsActive);

        setTimeout(() => {
            setIsQuestionHide(true);
        }, DELAY);
    };

    useEffect(() => {
        const regionsBlockHeight = regionsBlock?.current?.clientHeight || 0;
        const questionBlockHeight = questionBlock?.current?.clientHeight || 0;

        setRegionsHeight(regionsBlockHeight);
        setQuestionHeight(questionBlockHeight);
    }, [isRegionsActive]);

    return (
        <Box
            position="absolute"
            borderRadius="base"
            overflow="hidden"
            right={1}
            top={10}
            boxShadow="0 6px 14px -6px rgba(24, 39, 75, 0.12),
            0 10px 32px -4px rgba(24, 39, 75, 0.1)"
            transition="height 0.4s"
            zIndex="tooltip"
            style={{ height }}
        >
            <Box
                position="relative"
                overflow="hidden"
                backgroundColor={
                    initialColorMode === Theme.DARK
                        ? "base.spaceGrey"
                        : "base.primary"
                }
                py={2}
                px={3}
                textAlign="center"
                transition="opacity 0.5s"
                zIndex={isQuestionHide ? -1 : 1}
                opacity={isRegionsActive ? 0 : 1}
                ref={questionBlock}
            >
                <Text textStyle="title2" color="text.primary" mb={3}>
                    {question}
                </Text>
                <Button onClick={onRememberRegion} mb="1">
                    {buttonTitle}
                </Button>
                <Button onClick={handleSwitchRegions} variant="ghost">
                    {changeRegion}
                </Button>
            </Box>
            <Box
                position="absolute"
                top={0}
                right={0}
                left={0}
                backgroundColor={
                    initialColorMode === Theme.DARK
                        ? "base.spaceGrey"
                        : "base.primary"
                }
                borderRadius={1}
                overflow="hidden"
                transition="height 0.4s"
                style={{ height }}
            >
                <div ref={regionsBlock}>
                    <Heading px={2} pt={2} pb={1} textStyle="title">
                        {regionTitle}
                    </Heading>
                    <Regions
                        regions={regions}
                        onRedirectTo={onRedirectTo}
                        onSwitchRegions={onSwitchRegions}
                    />
                </div>
            </Box>
        </Box>
    );
};

export default LanguagesQuestion;

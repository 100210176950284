import {
    convertQueryStringToQueryObject,
    convertQueryObjectToQueryString,
} from "utils/queryString";
import { COOKIES, CountryVersion, Language } from "constants/enum";
import { getCookiesValueByName } from "utils/cookie";
import { regionSupportedLanguageConfig } from "configs/regionSupportedLanguageConfig";
import { versionToCountry } from "configs/versionsConfig";
import { RegionType } from "components/Regions/Regions.types";

import {
    LanguageType,
    regionSupportedLanguageType,
    regionSupportedLanguageConfigType,
} from "./RegionsAndLanguagesService.types";
import { getRegionName, getLanguageName } from "./dictionary";

const URL =
    "https://x2ke34uffe.execute-api.eu-south-1.amazonaws.com/api/v1/user/region-lang";

const regionSupportedLanguageConfigWithType: regionSupportedLanguageConfigType =
    regionSupportedLanguageConfig;

export const checkIsQuestionAlreadySeen = () =>
    document.cookie.indexOf(COOKIES.REFERRED_REGION) !== -1;

export const getCurrentRegion = (version: CountryVersion) => {
    return versionToCountry[version];
};

export const getUserCityFromCookie = () => {
    const userCity = getCookiesValueByName(COOKIES.IMPRESS_CITY);

    return userCity;
};

export const getTranslatedLanguages = (
    version: CountryVersion,
    currentLanguage: Language
) => {
    const currentRegion = getCurrentRegion(version);

    const languageIds =
        // TODO https://impress-global.atlassian.net/browse/DEV-401
        regionSupportedLanguageConfigWithType?.[currentRegion]?.languages || [];

    return languageIds.reduce((acc: LanguageType[], id: string) => {
        return [
            ...acc,
            {
                id,
                name: getLanguageName()[id],
                isActive: currentLanguage === id,
            },
        ];
    }, []);
};

const regions = Object.values(regionSupportedLanguageConfig).map(
    (item: regionSupportedLanguageType) => {
        return item.country;
    }
);

export const getTranslatedRegions = (version: CountryVersion) => {
    const currentRegion = getCurrentRegion(version as CountryVersion);

    const translatedRegions = regions.reduce(
        (acc: RegionType[], id: string) => {
            return [
                ...acc,
                {
                    id,
                    name: getRegionName()[id],
                    isActive: currentRegion === id,
                },
            ];
        },
        []
    );

    return translatedRegions.sort((region: RegionType) => {
        if (region.isActive) {
            return -1;
        }
        return 0;
    });
};

export const getTranslatedCurrentRegion = (version: CountryVersion) => {
    const currentRegion = getCurrentRegion(version as CountryVersion);

    return getRegionName()[currentRegion];
};

export const getDefaultLanguage = (country: string) => {
    return regionSupportedLanguageConfigWithType[country].defaultLanguage;
};

export const requestPostPreferred = async (data = {}) => {
    return await fetch(URL, {
        method: "POST",
        mode: "no-cors",
        cache: "no-cache",
        body: JSON.stringify(data),
    });
};

export const changeCountry = (name: string) => {
    const now = new Date();
    now.setMonth(now.getMonth() + 6);

    const defaultLanguage = getDefaultLanguage(name);

    document.cookie = `${
        COOKIES.REFERRED_REGION
    }=${name};expires=${now.toUTCString()};path=/`;
    document.cookie = `${
        COOKIES.PREFERRED_LANG
    }=${defaultLanguage};expires=${now.toUTCString()};path=/`;
    window.location.href = window.location.origin;
    // const data = {
    //     preferredRegion: name,
    //     preferredLang: defaultLanguage,
    // };

    // requestPostPreferred(data)
    //     .then(() => document.location.reload())
    //     .catch((error) => console.error("Request error:", error));
};

export const changeLanguage = (language: Language, version: CountryVersion) => {
    const now = new Date();
    now.setMonth(now.getMonth() + 6);

    const country = versionToCountry[version];

    document.cookie = `${
        COOKIES.REFERRED_REGION
    }=${country};expires=${now.toUTCString()};path=/`;
    document.cookie = `${
        COOKIES.PREFERRED_LANG
    }=${language};expires=${now.toUTCString()};path=/`;

    const queryString = location.search;
    const queryObject = convertQueryStringToQueryObject(queryString);

    if (queryObject.lang) {
        delete queryObject.lang;
    }

    const queryStringWithoutLang = convertQueryObjectToQueryString(queryObject);

    const { origin, pathname } = window.location;
    const url = origin + pathname;

    if (queryStringWithoutLang === "") {
        window.location.href = url;
    } else {
        window.location.href = `${url}?${encodeURI(queryStringWithoutLang)}`;
    }
};

export const getPreferredRegionCookie = (
    vesrion: CountryVersion,
    language: Language
) => {
    const region = getCurrentRegion(vesrion);
    const now = new Date();
    now.setMonth(now.getMonth() + 6);

    document.cookie = `${
        COOKIES.REFERRED_REGION
    }=${region};expires=${now.toUTCString()};path=/`;
    document.cookie = `${
        COOKIES.PREFERRED_LANG
    }=${language};expires=${now.toUTCString()};path=/`;

    // const data = {
    //     preferredRegion: name,
    //     preferredLang: language,
    // };

    // requestPostPreferred(data).catch((error) =>
    //     console.error("Request error:", error)
    // );
};

import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import { pathConfig } from "navigation/pathConfig";
import { CommonPageContentProps } from "content/content.types";

import commonMenu from "./menu";
import defaultContent from "./common";

const pageContent = (
    t: Translate,
    { version, language }: CommonPageContentProps
) => {
    const content = defaultContent(t, { version, language });

    return mergeDeepRight(content, {
        header: {
            menu: [
                {
                    id: "teen",
                    name: t("global:teensPageTitle"),
                    link: pathConfig.teen[version],
                },
                ...commonMenu(t, { version }),
            ],
        },
        footer: {
            company: {
                links: [
                    {
                        id: "b2b",
                        name: t("global:b2bPageTitle"),
                        link: pathConfig.associatedClinics[version],
                    },
                    {
                        id: "about",
                        name: t("global:whoWeArePageTitle"),
                        link: pathConfig.about[version],
                    },
                    {
                        id: "whyImpress",
                        name: t("global:whyImpressPageTitle"),
                        link: pathConfig.whyImpress[version],
                    },
                    {
                        id: "press",
                        name: t("global:pressPageTitle"),
                        link: pathConfig.pressReleases[version],
                    },
                    {
                        id: "careers",
                        name: t("global:careersPageTitle"),
                        link: "https://jobs.smile2impress.com/",
                        target: "_blank",
                    },
                    {
                        id: "blog",
                        name: t("global:blogPageTitle"),
                        link: pathConfig.article_common[version],
                        target: "_self",
                    },
                ],
            },
            trading: {
                text: t(`global:trading.${version}`),
            },
        },
    });
};

export default pageContent;

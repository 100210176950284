import { nanoid } from "nanoid";

import { postFacebookEvent } from "api/facebook";
import { COOKIES, CountryCode } from "constants/enum";
import { getCookiesValueByName } from "utils/cookie";

import { AnaliticData } from "./types";
import { FacebookEvents } from "./events";

export const sendFacebookEvent = (
    eventName: string,
    customData?: { [key: string]: string },
    eventData?: { eventID: string }
) => {
    if (!window.fbq) {
        return;
    }

    window.fbq("track", eventName, customData, eventData);
};

const sendPostFacebookLeadEvent = async ({
    eventID,
    version,
    cityTitle,
    firstName,
    lastName,
    email,
    phone,
}: { eventID: string } & AnaliticData) => {
    await postFacebookEvent({
        eventName: FacebookEvents.FORM_SUBMIT_FACEBOOK_LEAD_EVENT,
        eventSourceUrl: window?.location?.href,
        eventId: eventID,
        userData: {
            country: CountryCode[version],
            city: cityTitle,
            firstName,
            lastName,
            email,
            phone,
            fbp: decodeURIComponent(getCookiesValueByName(COOKIES.FBP)),
            fbc: decodeURIComponent(getCookiesValueByName(COOKIES.FBC)),
        },
    });
};

export const sendAllFacebookAnalitic = async ({
    version,
    cityTitle,
    firstName,
    lastName,
    email,
    phone,
}: AnaliticData) => {
    const eventID = nanoid();

    await sendPostFacebookLeadEvent({
        eventID,
        version,
        cityTitle,
        firstName,
        lastName,
        email,
        phone,
    });

    sendFacebookEvent(
        FacebookEvents.FORM_SUBMIT_FACEBOOK_LEAD_EVENT,
        {
            country: CountryCode[version],
            ...(cityTitle && { city: cityTitle }),
            first_name: firstName,
            last_name: lastName,
            email,
            phone_number: phone,
        },
        {
            eventID,
        }
    );
};

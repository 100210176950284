import { FC } from "react";
import { Box, Text, useTheme } from "@smile2impress/components";

import { Theme } from "constants/enum";

import { TableProps, CookiesItem } from "./Table.types";

export const Table: FC<TableProps> = ({
    listCookies = [],
    listTitles = [],
}) => {
    const { initialColorMode } = useTheme();
    return (
        <Box pt={2}>
            {listCookies.map((item: CookiesItem, index: number) => {
                return (
                    <Box
                        display="table"
                        w="100%"
                        mb={2}
                        p={1}
                        backgroundColor={
                            initialColorMode === Theme.DARK
                                ? "base.spaceGrey"
                                : "base.primary"
                        }
                        borderRadius="base"
                        _last={{
                            mb: 0,
                        }}
                        key={index}
                    >
                        {item?.map((point, index: number) => {
                            const title = listTitles[index] || "";

                            return (
                                <Box display="table-row" key={point}>
                                    <Text
                                        display="table-cell"
                                        textStyle="caption"
                                        w="40%"
                                    >
                                        {title}
                                    </Text>
                                    <Text
                                        display="table-cell"
                                        textStyle="caption"
                                        w="60%"
                                    >
                                        {point}
                                    </Text>
                                </Box>
                            );
                        })}
                    </Box>
                );
            })}
        </Box>
    );
};

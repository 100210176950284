import { FC, useCallback, useEffect } from "react";

import { useCookiesPolicyContext } from "context/cookiesPolicyContext";

import {
    CookiesPolicyPopup,
    CookiesPolicyPopupProps,
} from "./CookiesPolicyPopup";
import {
    CookiesPolicyBanner,
    CookiesPolicyBannerProps,
} from "./CookiesPolicyBanner";
import {
    setCookiesPolicyAccepted,
    getIsCookiesPolicyAccepted,
} from "./helpers";

type CookiesPolicyProps = {
    banner: CookiesPolicyBannerProps;
    popup: CookiesPolicyPopupProps;

    hideCookiesPolicyBanner?: boolean;
};

export const CookiesPolicy: FC<CookiesPolicyProps> = ({
    banner,
    popup,
    hideCookiesPolicyBanner = false,
}) => {
    const {
        isCookiesBannerVisible,
        isCookiesPopupVisible,
        setIsCookiesBannerVisible,
        setIsCookiesPopupVisible,
    } = useCookiesPolicyContext();

    const handleAcceptCookiesPolicy = useCallback(() => {
        document.body.style.overflow = "auto";
        setIsCookiesPopupVisible(false);
        setIsCookiesBannerVisible(false);
        setCookiesPolicyAccepted();
    }, []);

    const handleShowCookiesPolicyPopup = useCallback(() => {
        document.body.style.overflow = "hidden";
        setIsCookiesPopupVisible(true);
    }, []);

    const handleHideCookiesPolicyPopup = useCallback(() => {
        document.body.style.overflow = "auto";
        setIsCookiesPopupVisible(false);
        setIsCookiesBannerVisible(false);
    }, []);

    useEffect(() => {
        const isCookiesPolicyAccepted = getIsCookiesPolicyAccepted();

        if (!isCookiesPolicyAccepted) {
            setIsCookiesBannerVisible(true);
        }
    }, []);

    return (
        <>
            {isCookiesPopupVisible && (
                <CookiesPolicyPopup
                    {...popup}
                    onAcceptCookies={handleAcceptCookiesPolicy}
                    onClosePopup={handleHideCookiesPolicyPopup}
                />
            )}
            {isCookiesBannerVisible && !hideCookiesPolicyBanner && (
                <CookiesPolicyBanner
                    {...banner}
                    onShowPopup={handleShowCookiesPolicyPopup}
                    onAcceptCookiesPolicy={handleAcceptCookiesPolicy}
                />
            )}
        </>
    );
};

export const urlsConfig = {
    personalArea: "https://my.smile2impress.com",
    personalAreaBookingExists: "https://my.smile2impress.com/booking-exists",
    personalAreaBookingSuccess: "https://my.smile2impress.com/booking-success",
    googleFormB2b: {
        spain: "https://docs.google.com/forms/d/e/1FAIpQLSc0t-fDdUCmqSUEjs1zb5G7WTJDRDNMECS2OoIrSPlsm-s13g/viewform?vc=0&c=0&w=1&flr=0",
        italy: "https://docs.google.com/forms/d/1iGjQBdwTkFEvvMgdg8JkFs43DVMwKP4zgOgcV3dJpXA/viewform?edit_requested=true",
        portugal: "",
        uk: "https://docs.google.com/forms/d/1Ck0Dm_5RE_QiWUIpAhCEM7Q6bkJ3xQOkDMGxMjCKxFk/edit",
        france: "https://docs.google.com/forms/d/13DpVkUbrNcBdQlid3mMCfbIFQuwzx9iKpMKTIVYl8Ws/viewform?edit_requested=true",
        ukraine: "",
        germany: "",
        us: "",
    },
};

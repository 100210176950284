import { FC } from "react";
import {
    Flex,
    Icon,
    LogoIcon,
    LogoMonoIcon,
    CrossOutlineIcon,
    SmilelikeLogoDesktopIcon,
    SmilelikeLogoMobileIcon,
    LogoTeensIcon,
} from "@smile2impress/components";

import { CountryVersion, LogoTheme } from "constants/enum";

import { LogoProps, LogoColorTheme } from "./Logo.types";

export const Logo: FC<LogoProps> = ({
    logoTheme = LogoTheme.BICOLOR,
    logoColorTheme = LogoColorTheme.TEXT_PRIMARY,
    version,
    isTeens,
    hasMainLogo = true,
    specialAdditionalLogo = null,
    hasAdditionalLogo = true,
    isDesktopVersionOnly = false,
}) => {
    let additionLogo;
    let crossIcon;

    if (hasMainLogo && hasAdditionalLogo) {
        crossIcon = (
            <Icon
                as={CrossOutlineIcon}
                color="text.tertiary"
                w={2}
                h={2}
                mx={1}
            />
        );
    }

    if (hasMainLogo && specialAdditionalLogo) {
        crossIcon = (
            <Flex h="36px" w="1px" mx={2} backgroundColor="text.disabled" />
        );
    }

    if (version === CountryVersion.GERMANY) {
        additionLogo = (
            <>
                {crossIcon}
                <Icon
                    as={SmilelikeLogoMobileIcon}
                    display={isDesktopVersionOnly ? "none" : ["block", "none"]}
                    w="28px"
                    h="28px"
                />
                <Icon
                    as={SmilelikeLogoDesktopIcon}
                    display={isDesktopVersionOnly ? "block" : ["none", "block"]}
                    color="text.primary"
                    w="127px"
                    h="32px"
                    mb="20px"
                />
            </>
        );
    }

    if (isTeens) {
        additionLogo = <Icon as={LogoTeensIcon} w="103px" h="32px" />;
    }

    if (specialAdditionalLogo) {
        additionLogo = (
            <>
                {crossIcon}
                {specialAdditionalLogo}
            </>
        );
    }

    const logo =
        logoTheme === LogoTheme.BICOLOR ? (
            <Icon
                as={LogoIcon}
                w={[17.5, "160px"]}
                h="auto"
                color={logoColorTheme}
            />
        ) : (
            <Icon
                as={LogoMonoIcon}
                w={[17.5, "160px"]}
                h="auto"
                color={logoColorTheme}
            />
        );

    return (
        <Flex alignItems="center" width="100%">
            {hasMainLogo && logo}
            {hasAdditionalLogo && additionLogo}
        </Flex>
    );
};

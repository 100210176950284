import { useState, useMemo, useEffect } from "react";

import { ExperimentContext } from "context/experimentContext";
import { COOKIES } from "constants/enum";
import { getCookiesValueByName } from "utils/cookie";

import { ExperimentProviderProps } from "./ExperimentProvider.types";

export const ExperimentProvider = ({
    version,
    experimentCountries,
    children,
}: ExperimentProviderProps) => {
    const [isExperiment, setIsExperiment] = useState<boolean>(false);
    const [experimentGroupId, setExperimentGroupId] = useState<number>(0);

    const memoizedValue = useMemo(() => {
        return { isExperiment, experimentGroupId };
    }, [isExperiment, experimentGroupId]);

    useEffect(() => {
        if (experimentCountries.length) {
            const experimantalGroupCookieValue = Number(
                getCookiesValueByName(COOKIES.EXPERIMENTS)
            );

            if (
                experimantalGroupCookieValue &&
                experimentCountries.includes(version)
            ) {
                setIsExperiment(true);
                setExperimentGroupId(experimantalGroupCookieValue);
            }
        }
    }, []);

    return (
        <ExperimentContext.Provider value={memoizedValue}>
            {children}
        </ExperimentContext.Provider>
    );
};

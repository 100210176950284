import { FC } from "react";
import { BaseGrid, Container, GridItem } from "@smile2impress/components";

import { CountryVersion } from "constants/enum";
import { Logo } from "components/Logo";

import { FooterColumn, FooterColumnProps } from "./components/FooterColumn";
import {
    FooterWorkingHours,
    FooterWorkingHoursProps,
} from "./components/FooterWorkingHours";
import { FooterBadges, FooterBadgesProps } from "./components/FooterBadges";
import { FooterSocial, SocialNetworkType } from "./components/FooterSocial";
import {
    FooterCopyright,
    FooterCopyrightProps,
} from "./components/FooterCopyright";
import { FooterTrading, FooterTradingProps } from "./components/FooterTrading";

export type FooterContent = {
    company: FooterColumnProps;
    product: FooterColumnProps;
    legalNotice: FooterColumnProps;
    support: FooterColumnProps;
    workingHours: FooterWorkingHoursProps;
    copyright: FooterCopyrightProps;
    socialNetworks: SocialNetworkType[];
    badges: FooterBadgesProps;

    trading?: FooterTradingProps;
    hideBadges?: boolean;
    theme?: any;
};

export type FooterProps = {
    version: CountryVersion;
} & FooterContent;

export const Footer: FC<FooterProps> = ({
    theme,
    version,
    company,
    product,
    legalNotice,
    support,
    workingHours,
    copyright,
    socialNetworks,
    trading,
    badges,
    hideBadges = false,
}) => {
    const extraRowsAfterTrading = !!trading ? 1 : 0;

    return (
        <Container variant="base" position="relative" zIndex={1}>
            <BaseGrid>
                <GridItem colSpan={4} mb={[5]}>
                    <Logo
                        version={version}
                        isDesktopVersionOnly={version === CountryVersion.US}
                    />
                </GridItem>
                <GridItem
                    pb={[3]}
                    rowStart={[2]}
                    colSpan={[4, 8, 12]}
                    borderTop="0.5px solid"
                    borderColor="line.secondary"
                />
                <GridItem rowStart={3} colSpan={[4, 2, 3]}>
                    <FooterColumn {...company} />
                </GridItem>
                <GridItem
                    rowStart={[4, 3]}
                    colSpan={[4, 2, 3]}
                    colStart={[1, 3, 4]}
                    mt={[4, 0]}
                >
                    <FooterColumn {...product} />
                </GridItem>
                <GridItem
                    rowStart={[5, 3]}
                    colSpan={[4, 2, 3]}
                    colStart={[1, 5, 7]}
                    mt={[4, 0]}
                >
                    <FooterColumn {...legalNotice} />
                </GridItem>
                <GridItem
                    rowStart={[6, 3]}
                    colSpan={[4, 2, 3]}
                    colStart={[1, 7, 10]}
                    mt={[4, 0]}
                >
                    <FooterColumn {...support} />
                    <FooterWorkingHours {...workingHours} />
                </GridItem>
                {!hideBadges && (
                    <GridItem
                        rowStart={[7, 1]}
                        colSpan={[4, 3]}
                        colStart={[1, 6, 10]}
                        mt={[4, 0]}
                        mb={[0, 3]}
                    >
                        <FooterBadges {...{ ...badges, theme }} />
                    </GridItem>
                )}
                {trading && (
                    <GridItem
                        mt={[6, 3]}
                        rowStart={[8, 4]}
                        colSpan={[4, 8, 12]}
                    >
                        <FooterTrading {...trading} />
                    </GridItem>
                )}
                <GridItem
                    my={[3]}
                    rowStart={[
                        8 + extraRowsAfterTrading,
                        4 + extraRowsAfterTrading,
                    ]}
                    colSpan={[4, 8, 12]}
                    borderTop="0.5px solid"
                    borderColor="line.secondary"
                />
                <GridItem
                    rowStart={[
                        9 + extraRowsAfterTrading,
                        5 + extraRowsAfterTrading,
                    ]}
                    colSpan={[4, 2]}
                    colStart={[1]}
                    mb={[2, 3]}
                >
                    <FooterSocial socialNetworks={socialNetworks} />
                </GridItem>
                <GridItem
                    rowStart={[
                        10 + extraRowsAfterTrading,
                        5 + extraRowsAfterTrading,
                    ]}
                    colSpan={[4, 3, 6]}
                    colStart={[1, 6, 7]}
                    mb={[3]}
                    textAlign={["left", "right"]}
                >
                    <FooterCopyright {...copyright} />
                </GridItem>
            </BaseGrid>
        </Container>
    );
};

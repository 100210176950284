import { Box, Text } from "@smile2impress/components";
import { FC } from "react";

export type FooterWorkingHoursProps = {
    weekdays: string;
    weekends: string;
};

export const FooterWorkingHours: FC<FooterWorkingHoursProps> = ({
    weekdays,
    weekends,
}) => (
    <Box pt={[2]} pb={[2]}>
        <Text textStyle="caption" color="text.secondary">
            {weekdays}
        </Text>
        <Text textStyle="caption" color="text.secondary">
            {weekends}
        </Text>
    </Box>
);

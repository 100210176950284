import { FunctionComponent, useState } from "react";
import { Flex, Box, Text } from "@smile2impress/components";

import { SwitcherProps } from "./Switcher.types";

export const Switcher: FunctionComponent<SwitcherProps> = (props) => {
    const { active, inactive } = props;

    const [isActiveSCookies, setIsActiveCookies] = useState(true);

    const handleSwitchActives = () => {
        setIsActiveCookies(!isActiveSCookies);
    };

    const label = isActiveSCookies ? active : inactive;

    return (
        <Flex alignItems="center" mb={2} onClick={handleSwitchActives}>
            <Box
                position="relative"
                w={8}
                h={5}
                mr={2}
                borderRadius="lg"
                backgroundColor="#e2e2df"
                transition="background-color 0.25s ease"
                cursor="pointer"
                _before={{
                    content: "''",
                    position: "absolute",
                    w: 4,
                    height: 4,
                    left: isActiveSCookies ? "28px" : "4px",
                    top: "4px",
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                    transition: "left 0.25s ease",
                }}
                {...(isActiveSCookies && {
                    backgroundColor: "action.success",
                })}
            />
            <Text
                textStyle="body"
                color={isActiveSCookies ? "#7fb800" : "text.secondary"}
            >
                {label}
            </Text>
        </Flex>
    );
};

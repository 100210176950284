import { FC, MouseEvent, useRef, useEffect } from "react";
import classnames from "classnames/bind";
import { useSwipeable } from "react-swipeable";
import {
    Container,
    Flex,
    Heading,
    Box,
    Icon,
    CrossOutlineIcon,
    Button,
} from "@smile2impress/components";
import { lock, unlock, clearBodyLocks } from "tua-body-scroll-lock";

import { useLocationContext } from "context/locationContext";
import { Language, Theme } from "constants/enum";

import content from "./content";
import { ModalSheetProps } from "./ModalSheet.types";
import styles from "./ModalSheet.module.scss";

const cx = classnames.bind(styles);

export const ModalSheet: FC<ModalSheetProps> = ({
    theme,
    title,
    children,
    className,
    isOpen,
    hasCloseButton = true,
    hasBlackout = true,
    hasValue,
    onRefSet,
    onClose,
}) => {
    const overlayRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { language } = useLocationContext();
    const contentByLanguage = content[language || Language.SPAIN];

    useEffect(() => {
        if (onRefSet && overlayRef?.current) {
            onRefSet(overlayRef.current);
        }
    }, [overlayRef]);

    const handlers = useSwipeable({
        onSwipedDown: () => {
            onClose();
        },
    });

    useEffect(() => {
        if (isOpen) {
            lock(overlayRef.current);
        }
        return () => {
            unlock(overlayRef.current);
            clearBodyLocks();
        };
    }, [isOpen]);

    const onClickOverlay = (e: MouseEvent<HTMLDivElement>) => {
        if (!overlayRef.current?.contains(e.target as HTMLDivElement)) {
            onClose();

            if (buttonRef?.current) buttonRef.current.click();
        }
    };

    return (
        <>
            <Button type="submit" display="none" ref={buttonRef} />
            <div
                className={cx("overlay", {
                    "overlay_has-blackout": hasBlackout,
                    overlay_open: isOpen,
                })}
                onClick={onClickOverlay}
            >
                <Box
                    ref={overlayRef}
                    className={cx("root", className, {
                        "fade-in-up-big": isOpen,
                    })}
                    {...(theme?.initialColorMode === Theme.DARK && {
                        backgroundColor: "base.spaceGrey",
                    })}
                >
                    <Container position="relative">
                        <Flex
                            h={6}
                            pl={3}
                            pr={1}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Heading textStyle="title2">{title}</Heading>

                            {hasCloseButton && hasValue && (
                                <Button
                                    position="absolute"
                                    top={0}
                                    right={0}
                                    zIndex={104}
                                    variant="ghost"
                                    size="sm"
                                    p={3}
                                    onClick={onClose}
                                >
                                    {contentByLanguage.textDone}
                                </Button>
                            )}
                            {hasCloseButton && !hasValue && (
                                <Flex
                                    w={6}
                                    h={6}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Button
                                        type="button"
                                        onClick={onClose}
                                        variant="ghost"
                                    >
                                        <Icon
                                            as={CrossOutlineIcon}
                                            boxSize={3}
                                            color="text.primary"
                                        />
                                    </Button>
                                </Flex>
                            )}
                        </Flex>
                        <Box px={3}>{children}</Box>
                    </Container>
                    <div {...handlers} className={cx("box")} />
                </Box>
            </div>
        </>
    );
};

export const getLanguageName: any = () => ({
    it: "Italiano",
    es: "Español",
    pt: "Português",
    en: "English",
    fr: "Français",
    de: "Deutsch",
    uk: "Українська",
});

export const getRegionName: any = () => ({
    Italy: "Italia",
    Spain: "España",
    Germany: "Deutschland",
    Portugal: "Portugal",
    France: "France",
    Switzerland: "Suisse",
    "United Kingdom": "United Kingdom",
    "United States": "United States",
    Ukraine: "Україна",
});

import { CountryVersion } from "constants/enum";

export interface TelegramWidgetProps {
    href: string;

    source?: TelegramSourceProps;
    hide?: boolean;
    version?: CountryVersion;
    className?: string;
}

export enum TelegramSourceProps {
    FROM_SITE = "from-site",
    FROM_LANDING = "from-landing",
}

import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { CommonPageContentProps } from "content/content.types";
import { pathConfig } from "navigation/pathConfig";
import contacts from "configs/contacts.json";

import defaultContent from "./common";

const pageContent = (
    t: Translate,
    { version, language }: CommonPageContentProps
) => {
    const content = defaultContent(t, { version, language });

    return mergeDeepRight(content, {
        header: {
            menu: [
                {
                    id: "whyImpress",
                    name: t("global:whyImpressPageTitle"),
                    link: pathConfig.whyImpress[version],
                },
                {
                    id: "howItWorks",
                    name: t("global:howItWorksPageTitle"),
                    link: pathConfig.howItWorks[version],
                },
                {
                    id: "prices",
                    name: t("global:pricePageTitle"),
                    link: pathConfig.prices[version],
                },
                {
                    id: "locations",
                    name: t("global:locationPageTitle"),
                    link: pathConfig.locations[version],
                },
            ],
        },
        contacts: {
            wa: null,
            tg: {
                href: contacts.telegram[version].link,
            },
        },
    });
};

export default pageContent;

import { FunctionComponent, useRef, useState, useEffect } from "react";
import {
    Heading,
    Flex,
    Box,
    Icon,
    Text,
    CheckmarkThinOutlineIcon,
    ChevronRightOutlineIcon,
    useTheme,
} from "@smile2impress/components";

import { useClickOutside } from "hooks/useClickOutside";
import { FlagIcon } from "components/Icons/FlagIcon";
import { LanguageType } from "services/RegionsAndLanguagesService/RegionsAndLanguagesService.types";
import { Regions } from "components/Regions";
import { Theme } from "constants/enum";

import { RegionsLanguagesProps } from "./RegionsLanguages.types";

export const RegionsLanguages: FunctionComponent<RegionsLanguagesProps> = ({
    content,
    regions,
    languages,
    version,
    onRedirectTo,
    currentRegion,
    onChangeLanguage,
    onClose,
}) => {
    const { initialColorMode } = useTheme();
    const { regionTitle, languageTitle } = content;

    const [isRegionsActive, setIsRegionsActive] = useState(false);
    const [regionsHeight, setRegionsHeight] = useState(0);
    const [innerHeight, setInnerHeight] = useState(0);
    const [currentRegionHeight, setCurrentRegionHeight] = useState(0);

    const regionsBlock = useRef<HTMLUListElement>(null);
    const languagesBlock = useRef<HTMLDivElement>(null);
    const currentRegionBlock = useRef<HTMLDivElement>(null);
    const rootBlock = useRef<HTMLDivElement>(null);

    const height = isRegionsActive ? regionsHeight : innerHeight || "auto";
    const regionHeight = isRegionsActive ? regionsHeight : currentRegionHeight;

    const handleSwitchRegions = () => {
        setIsRegionsActive(!isRegionsActive);
    };

    useClickOutside(rootBlock, onClose);

    useEffect(() => {
        const regionsHeight = regionsBlock?.current?.clientHeight || 0;
        const languagesHeight = languagesBlock?.current?.clientHeight || 0;
        const heightCurrentRegionBlock =
            currentRegionBlock?.current?.clientHeight || 0;

        setRegionsHeight(regionsHeight);
        setCurrentRegionHeight(heightCurrentRegionBlock);
        setInnerHeight(languagesHeight + heightCurrentRegionBlock);
    }, [isRegionsActive]);

    return (
        <Box
            ref={rootBlock}
            position="absolute"
            right={1}
            top={10}
            w="272px"
            borderRadius="base"
            overflow="hidden"
            pt={2}
            boxShadow="0 6px 14px -6px rgba(24, 39, 75, 0.12), 0 10px 32px -4px rgba(24, 39, 75, 0.1)"
            zIndex="tooltip"
            backgroundColor={
                initialColorMode === Theme.DARK
                    ? "base.spaceGrey"
                    : "base.primary"
            }
        >
            <Heading textStyle="caption" pl={3} pr={2} pb={1}>
                {regionTitle}
            </Heading>
            <Box overflow="hidden" transition="height 0.4s" style={{ height }}>
                <Flex
                    justifyContent="space-between"
                    mb={1}
                    w="542px"
                    style={{ height: regionHeight }}
                    transition={isRegionsActive ? "transform 0.4s" : "none"}
                    transform={
                        isRegionsActive ? "translate3d(-50%, 0, 0)" : "none"
                    }
                >
                    <Box w="50%">
                        <Flex
                            alignItems="center"
                            cursor="pointer"
                            py={2}
                            pr={3}
                            onClick={handleSwitchRegions}
                            ref={currentRegionBlock}
                            position="relative"
                            justifyContent="space-between"
                        >
                            <Flex>
                                <Box position="relative" w={3} h={3} pl={3}>
                                    <FlagIcon version={version} />
                                </Box>
                                <Text textStyle="body" pl={4}>
                                    {currentRegion}
                                </Text>
                            </Flex>
                            <Icon as={ChevronRightOutlineIcon} size="sm" />
                        </Flex>
                    </Box>
                    {isRegionsActive && (
                        <Box w="50%">
                            <Regions
                                ref={regionsBlock}
                                regions={regions}
                                onRedirectTo={onRedirectTo}
                                onSwitchRegions={handleSwitchRegions}
                            />
                        </Box>
                    )}
                </Flex>
                <div ref={languagesBlock}>
                    <Heading textStyle="caption" pl={3} pr={2} pb={1}>
                        {languageTitle}
                    </Heading>
                    <Box as="ul" listStyleType="none" pb={2} m={0}>
                        {languages.map((language: LanguageType) => {
                            const { id, name, isActive } = language;

                            return (
                                <Flex
                                    as="li"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    cursor="pointer"
                                    position={isActive ? "relative" : "static"}
                                    color={
                                        isActive
                                            ? "text.accent"
                                            : "text.primary"
                                    }
                                    py={2}
                                    px={3}
                                    key={id}
                                    onClick={() => onChangeLanguage(id)}
                                >
                                    {name}
                                    {isActive && (
                                        <Icon
                                            as={CheckmarkThinOutlineIcon}
                                            color="text.accent"
                                        />
                                    )}
                                </Flex>
                            );
                        })}
                    </Box>
                </div>
            </Box>
        </Box>
    );
};

export default RegionsLanguages;

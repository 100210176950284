import { Text } from "@smile2impress/components";
import { FC } from "react";

export type FooterTradingProps = {
    text: string;
};

export const FooterTrading: FC<FooterTradingProps> = ({ text }) => (
    <Text textStyle="caption" color="text.tertiary">
        {text}
    </Text>
);

import {
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useRouter } from "next/router";
import { useSwipeable } from "react-swipeable";
import {
    Box,
    Button,
    Text,
    Container,
    Flex,
    Icon,
    Link,
    DotsHorizontalOutlineIcon,
    MenuOutlineIcon,
    CrossOutlineIcon,
    ProfileFilledIcon,
} from "@smile2impress/components";

import { useWindowDimensions } from "hooks/useWindowDimensions";
import { useCheckAuthentication } from "hooks/useCheckAuthentication";
import { useClickOutside } from "hooks/useClickOutside";
import { ThemeConfig, LogoTheme, Theme } from "constants/enum";
import { throttle } from "utils/throttle";
import { ModalSheet } from "components/ModalSheet";
import { Logo } from "components/Logo";
import { getAnalyticsId, GetAnalyticsIdElement } from "utils/AnalyticsId";
import { RegionsAndLanguagesService } from "services/RegionsAndLanguagesService";

import {
    ACTION_BUTTON_WIDTH,
    AVERAGE_ELEMENT_WIDTH,
    HEADER_CONTAINER_MAX_WIDTH,
    HEADER_GAPS,
    LOGIN_BUTTON_WIDTH,
    LOGO_WIDTH,
    REGIONS_WIDTH,
} from "./consts";
import { HeaderProps } from "./Header.types";

const BODY_VISIBILITY_Y = {
    HIDDEN: {
        position: "fixed",
        width: "100%",
        height: "100%",
        "overflow-y": "hidden",
        "touch-action": "none",
    },
    SHOW: {
        position: null,
        width: null,
        height: null,
        "overflow-y": "auto",
        "touch-action": "pan-y",
    },
};
const MODAL_VISIBILITY_Y = {
    HIDDEN: {
        position: null,
        "overflow-y": "hidden",
        "touch-action": "none",
    },
    SHOW: {
        position: "fixed",
        "box-shadow": "0px 10px 40px rgba(0, 0, 0, 0.2)",
        "overflow-y": "auto",
        "touch-action": "pan-y",
    },
};

export const Header: FunctionComponent<HeaderProps> = ({
    theme,
    menu,
    hideMenu,
    logoLink,
    appointment,
    login,
    isErrorPage,
    currentPage,
    languagesService,
    hideLanguageQuestion,
    isCurrentPageWithoutFix = false,
    logoTheme = LogoTheme.BICOLOR,
    version,
}) => {
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    const [isMenuMoreActive, setIsMenuMoreActive] = useState(false);
    const [headerScrollHeight, setHeaderScrollHeight] = useState<number>(-1);
    const [modalSheetElement, setModalSheetElement] =
        useState<HTMLDivElement>();
    const mobileMenuRef = useRef<HTMLDivElement | null>(null);
    const moreMenuRef = useRef<HTMLUListElement | null>(null);
    const menuRef = useRef<HTMLDivElement | null>(null);

    const [isPendingAuth, isSuccessAuth] = useCheckAuthentication();
    const { width } = useWindowDimensions();
    const handlers = useSwipeable({
        onSwipedDown: () => {
            handleSideMenuRelatedButtonsClick(false);
        },
        trackMouse: true,
    });
    const { pathname } = useRouter() ?? {};

    const hasWindow = typeof window !== "undefined";

    const callback = throttle(() => {
        const top = headerScrollHeight || 0;

        setIsHeaderFixed(pageYOffset > top);
    }, 10);

    const handleSideMenuRelatedButtonsClick = (isMenuOpen: boolean) => {
        setIsMenuActive(isMenuOpen);
    };

    const handleMenu = useCallback(() => {
        setIsMenuActive(!isMenuActive);
    }, [isMenuActive]);

    useClickOutside(mobileMenuRef, () => {
        handleSideMenuRelatedButtonsClick(false);
    });

    useClickOutside(moreMenuRef, () => {
        setIsMenuMoreActive(false);
    });

    useEffect(() => {
        if (hasWindow && headerScrollHeight !== -1) {
            setIsHeaderFixed(pageYOffset > headerScrollHeight);

            if (!isErrorPage && !hideMenu) {
                window.addEventListener("scroll", callback);

                return () => window.removeEventListener("scroll", callback);
            }
        }
    }, [hasWindow, headerScrollHeight !== -1]);

    useEffect(() => {
        if (menuRef.current) {
            const { top } = menuRef.current?.getBoundingClientRect();
            setHeaderScrollHeight(top);
        }
    }, [hasWindow && menuRef.current]);

    useEffect(() => {
        if (isMenuActive) {
            Object.assign(document.body.style, {
                "--z-index-header": "102",
                ...BODY_VISIBILITY_Y.HIDDEN,
            });
        } else {
            Object.assign(document.body.style, {
                "--z-index-header": "100",
                ...BODY_VISIBILITY_Y.SHOW,
            });
        }

        if (!modalSheetElement) {
            return;
        }

        if (isMenuActive) {
            Object.assign(modalSheetElement.style, {
                ...MODAL_VISIBILITY_Y.SHOW,
            });
        } else {
            Object.assign(modalSheetElement.style, {
                ...MODAL_VISIBILITY_Y.HIDDEN,
            });
        }
    }, [isMenuActive]);

    const handleRefSet = (modalSheetElement: HTMLDivElement) => {
        setModalSheetElement(modalSheetElement);
    };

    const showMoreMenu = () => {
        setIsMenuMoreActive(!isMenuMoreActive);
    };

    const checkIfActivePage = (id: string) =>
        id === currentPage || id === pathname?.replace("/", "");

    const widthHeader =
        width > HEADER_CONTAINER_MAX_WIDTH ? HEADER_CONTAINER_MAX_WIDTH : width;

    const shownElementsNumber = Math.floor(
        (widthHeader -
            LOGO_WIDTH -
            HEADER_GAPS -
            ACTION_BUTTON_WIDTH[
                languagesService.currentLanguage as keyof typeof ACTION_BUTTON_WIDTH
            ] -
            LOGIN_BUTTON_WIDTH[
                languagesService.currentLanguage as keyof typeof LOGIN_BUTTON_WIDTH
            ] -
            REGIONS_WIDTH) /
            AVERAGE_ELEMENT_WIDTH[
                languagesService.currentLanguage as keyof typeof AVERAGE_ELEMENT_WIDTH
            ]
    );
    const shownElements =
        shownElementsNumber > 0 ? menu.slice(0, shownElementsNumber) : menu;
    const hiddenElements =
        shownElementsNumber > 0 ? menu.slice(shownElementsNumber) : [];
    const isActiveElementHidden = hiddenElements.some(({ id }) =>
        checkIfActivePage(id)
    );

    return (
        <Box
            as="header"
            position="relative"
            display="block"
            height={["56px", "72px"]}
            width="100%"
            backgroundColor={ThemeConfig.headerBackground}
            {...handlers}
        >
            <Box
                width="100%"
                height="100%"
                pl="24px"
                position="absolute"
                top="0px"
                left="0px"
                zIndex="101"
                ref={menuRef}
                backgroundColor={ThemeConfig.headerBackground}
                {...(isErrorPage && {
                    position: "static",
                })}
                {...(isHeaderFixed && {
                    position: "fixed",
                    top: "0px",
                    height: ["56px", "72px"],
                })}
                {...(isCurrentPageWithoutFix && {
                    position: "static",
                })}
            >
                <Container
                    size="xl"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    margin="0 auto"
                    height="100%"
                >
                    <Link href={logoLink} display="flex" mr={[null, "40px"]}>
                        <Logo logoTheme={logoTheme} version={version} />
                    </Link>

                    <Flex
                        as="nav"
                        display={hideMenu ? "none" : "inherit"}
                        alignItems="center"
                        height="100%"
                    >
                        {shownElements.length > 0 && (
                            <Flex
                                as="ul"
                                listStyleType="none"
                                display={["none", "inherit"]}
                                alignItems="center"
                                pl="0px"
                                m="0px"
                                height="100%"
                            >
                                {shownElements.map(
                                    ({ id, link, name }, key) => {
                                        const isActivePage =
                                            checkIfActivePage(id);

                                        return (
                                            link && (
                                                <Box
                                                    as="li"
                                                    key={key}
                                                    height="100%"
                                                    pr={3}
                                                >
                                                    <Link
                                                        href={link}
                                                        position="relative"
                                                        display="flex"
                                                        alignItems="center"
                                                        py={1.5}
                                                        height="100%"
                                                        whiteSpace="nowrap"
                                                        fontWeight="500"
                                                        fontSize="17px"
                                                        lineHeight="24px"
                                                        color={
                                                            isActivePage
                                                                ? ThemeConfig.linkActiveColor
                                                                : ThemeConfig.linkColor
                                                        }
                                                        borderBottom="none"
                                                        transition="color 0.3s linear"
                                                        sx={{
                                                            "&::after": {
                                                                content: "''",
                                                                display:
                                                                    "block",
                                                                position:
                                                                    "absolute",
                                                                left: "0px",
                                                                bottom: "0px",
                                                                width: "100%",
                                                                height: "2px",
                                                                backgroundColor:
                                                                    ThemeConfig.linkActiveColor,
                                                                transform:
                                                                    isActivePage
                                                                        ? "scaleX(1)"
                                                                        : "scaleX(0)",
                                                                transition:
                                                                    "transform 0.3s ease-in-out",
                                                            },
                                                            "&:hover": {
                                                                color: ThemeConfig.linkActiveColor,
                                                                "&::after": {
                                                                    transform:
                                                                        "scaleX(1)",
                                                                    color: ThemeConfig.linkActiveColor,
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {name}
                                                    </Link>
                                                </Box>
                                            )
                                        );
                                    }
                                )}
                            </Flex>
                        )}

                        {hiddenElements.length > 0 && (
                            <Flex
                                as="button"
                                onClick={showMoreMenu}
                                cursor="pointer"
                                position="relative"
                                display={["none", "inherit"]}
                                alignItems="center"
                                height="100%"
                                mr="24px"
                                sx={{
                                    "&::after": {
                                        content: "''",
                                        display: "block",
                                        position: "absolute",
                                        left: "0px",
                                        bottom: "0px",
                                        width: "100%",
                                        height: "2px",
                                        backgroundColor:
                                            ThemeConfig.linkActiveColor,
                                        transform:
                                            isMenuMoreActive ||
                                            isActiveElementHidden
                                                ? "scaleX(1)"
                                                : "scaleX(0)",
                                        transition:
                                            "transform 0.3s ease-in-out",
                                    },
                                    "&:hover": {
                                        "&::after": {
                                            transform: "scaleX(1)",
                                        },
                                    },
                                }}
                            >
                                <Icon
                                    as={DotsHorizontalOutlineIcon}
                                    color={ThemeConfig.iconColor}
                                />

                                {isMenuMoreActive && (
                                    <Box
                                        as="ul"
                                        // @ts-ignore
                                        ref={moreMenuRef}
                                        position="absolute"
                                        zIndex="102"
                                        top="83px"
                                        left="18px"
                                        transform="translateX(-50%)"
                                        p="20px 16px"
                                        textAlign="left"
                                        whiteSpace="nowrap"
                                        borderRadius="sm"
                                        boxShadow="0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)"
                                        backgroundColor={
                                            theme?.initialColorMode ===
                                            Theme.DARK
                                                ? "base.spaceGrey"
                                                : "base.primary"
                                        }
                                        listStyleType="none"
                                    >
                                        {hiddenElements.map((item, key) => {
                                            const { id, link, name } = item;
                                            const isActivePage =
                                                checkIfActivePage(id);

                                            return (
                                                link && (
                                                    <Box
                                                        as="li"
                                                        key={key}
                                                        mt="16px"
                                                        sx={{
                                                            "&:first-child": {
                                                                mt: "0px",
                                                            },
                                                        }}
                                                    >
                                                        <Link
                                                            href={link}
                                                            fontSize="17px"
                                                            lineHeight="24px"
                                                            transition="color 0.2s ease-in"
                                                            color={
                                                                isActivePage
                                                                    ? ThemeConfig.linkActiveColor
                                                                    : ThemeConfig.linkMoreMenuColor
                                                            }
                                                            sx={{
                                                                "&:hover": {
                                                                    color: ThemeConfig.linkActiveColor,
                                                                },
                                                            }}
                                                        >
                                                            {name}
                                                        </Link>
                                                    </Box>
                                                )
                                            );
                                        })}
                                    </Box>
                                )}
                            </Flex>
                        )}

                        {!isPendingAuth && (
                            <Button
                                as={Link}
                                id={getAnalyticsId({
                                    zone: "header",
                                    element:
                                        GetAnalyticsIdElement.GTM_LOGIN_BUTTON,
                                })}
                                href={login.link}
                                size={isSuccessAuth ? "sm" : "md"}
                                variant={ThemeConfig.loginButtonColor}
                                display={["none", "inherit"]}
                                borderRadius={isSuccessAuth ? "50%" : "lg"}
                                mr="2"
                            >
                                {isSuccessAuth ? (
                                    <Icon
                                        as={ProfileFilledIcon}
                                        color={ThemeConfig.iconColor}
                                        boxSize={2.5}
                                    />
                                ) : (
                                    login.name
                                )}
                            </Button>
                        )}

                        <Button
                            as={Link}
                            href={appointment.link}
                            size="md"
                            variant={ThemeConfig.appointmentButtonColor}
                            display={["none", "inherit"]}
                        >
                            {appointment.name}
                        </Button>

                        {!languagesService.hideRegionSelector && (
                            <Flex
                                display={["none", "flex"]}
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                                color={ThemeConfig.linkColor}
                            >
                                <RegionsAndLanguagesService
                                    {...languagesService}
                                    hideLanguageQuestion={hideLanguageQuestion}
                                />
                            </Flex>
                        )}

                        <Button
                            onClick={() => {
                                handleSideMenuRelatedButtonsClick(
                                    !isMenuActive
                                );
                            }}
                            variant="secondary"
                            width="32px"
                            height="32px"
                            mr={3}
                            p="0px"
                            borderRadius="sm"
                            minWidth="auto"
                            display={["inherit", "none"]}
                        >
                            {isMenuActive ? (
                                <Icon
                                    as={CrossOutlineIcon}
                                    color={ThemeConfig.iconColor}
                                />
                            ) : (
                                <Icon
                                    as={MenuOutlineIcon}
                                    color={ThemeConfig.iconColor}
                                />
                            )}
                        </Button>
                    </Flex>

                    <ModalSheet
                        theme={theme}
                        isOpen={isMenuActive}
                        hasCloseButton={false}
                        hasBlackout={false}
                        onRefSet={handleRefSet}
                        onClose={() => {
                            handleSideMenuRelatedButtonsClick(!isMenuActive);
                        }}
                    >
                        <Box as="nav">
                            <Box as="ul" pl="0" listStyleType="none" m="0">
                                {menu.map(({ link, name }, key) => {
                                    if (!link) {
                                        return;
                                    }

                                    return (
                                        <Box as="li" key={key}>
                                            <Link
                                                href={link}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                width="100%"
                                            >
                                                <Text as="span" py={2}>
                                                    {name}
                                                </Text>
                                            </Link>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>

                        {!languagesService.hideRegionSelector && (
                            <Box textAlign="center" pl={[null, 0]}>
                                <RegionsAndLanguagesService
                                    {...languagesService}
                                    isMobileMenu
                                    onCloseMenu={handleMenu}
                                />
                            </Box>
                        )}

                        <Box my={7}>
                            <Button
                                as={Link}
                                href={appointment.link}
                                size="md"
                                width="100%"
                                mb={2}
                            >
                                {appointment.name}
                            </Button>

                            {!isPendingAuth && (
                                <Button
                                    as={Link}
                                    id={getAnalyticsId({
                                        zone: "header",
                                        element:
                                            GetAnalyticsIdElement.GTM_LOGIN_BUTTON,
                                    })}
                                    href={login.link}
                                    size="md"
                                    variant="secondary"
                                    width="100%"
                                >
                                    {isSuccessAuth ? (
                                        <Icon
                                            as={ProfileFilledIcon}
                                            color="text.primary"
                                            boxSize={3}
                                        />
                                    ) : (
                                        login.name
                                    )}
                                </Button>
                            )}
                        </Box>
                    </ModalSheet>
                </Container>
            </Box>
        </Box>
    );
};

import { FC } from "react";
import {
    Box,
    Button,
    Flex,
    Link,
    Text,
    useTheme,
} from "@smile2impress/components";

import { noop } from "utils/noop";
import { Theme } from "constants/enum";

import { CookiesPolicyBannerProps } from "./CookiesPolicyBanner.types";

export const CookiesPolicyBanner: FC<CookiesPolicyBannerProps> = ({
    link,
    accept,
    content,
    settings,
    onShowPopup = noop,
    onAcceptCookiesPolicy = noop,
}) => {
    const { initialColorMode } = useTheme();
    return (
        <Box
            position="fixed"
            bottom={[1, 3]}
            left={[1, 3]}
            w="calc(100% - 10px)"
            maxW={["auto", "604px"]}
            p={2}
            backgroundColor={
                initialColorMode === Theme.DARK
                    ? "base.spaceGrey"
                    : "base.primary"
            }
            borderRadius="base"
            zIndex="toast"
        >
            <Box mb={2}>
                <Text textStyle="caption">
                    <>
                        {content}
                        <Link href={link.href} fontWeight="bold">
                            {link.name}
                        </Link>
                    </>
                </Text>
            </Box>

            <Flex flexWrap="wrap">
                <Button
                    onClick={onAcceptCookiesPolicy}
                    size="md"
                    mr="3"
                    mb="0.5"
                >
                    {accept}
                </Button>
                <Button
                    onClick={onShowPopup}
                    variant="ghost"
                    size="md"
                    mb="0.5"
                >
                    {settings}
                </Button>
            </Flex>
        </Box>
    );
};

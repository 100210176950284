import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { CommonPageContentProps } from "content/content.types";
import { pathConfig } from "navigation/pathConfig";

import defaultContent from "./common";

const pageContent = (
    t: Translate,
    { version, language }: CommonPageContentProps
) => {
    const content = defaultContent(t, { version, language });

    return mergeDeepRight(content, {
        footer: {
            product: {
                links: [
                    {
                        id: "teens",
                        name: t("global:teensPageTitle"),
                        link: pathConfig.teen[version],
                    },
                    {
                        id: "results",
                        name: t("global:resultsPageTitle"),
                        link: pathConfig.results[version],
                    },
                    {
                        id: "locations",
                        name: t("global:locationPageTitle"),
                        link: pathConfig.locations[version],
                    },
                    {
                        id: "howItWorks",
                        name: t("global:howItWorksPageTitle"),
                        link: pathConfig.howItWorks[version],
                    },
                    {
                        id: "telehealthConsent",
                        name: t("global:telehealthConsent"),
                        link: pathConfig.telehealthConsent[version],
                    },
                ],
            },
            legalNotice: {
                title: t("global:legalNoticeFooterText"),
                links: [
                    {
                        id: "privacyPolicy",
                        name: t("global:privacyPolicy"),
                        link: pathConfig.privacyPolicy[version]?.[language],
                    },
                    {
                        id: "cookiesPolicy",
                        name: t("global:cookiesPolicy"),
                        link: pathConfig.cookiesPolicy[version]?.[language],
                    },
                    {
                        id: "cookiesSettings",
                        name: t("global:cookiesSettings"),
                        cookiesSetting: true,
                    },
                    {
                        id: "terms",
                        name: t("global:termsAndConditions"),
                        link: pathConfig.terms[version],
                    },
                    {
                        id: "recordConsent",
                        name: t("global:recordTakingConsent"),
                        link: pathConfig.recordConsent[version],
                    },
                ],
            },
        },
        contacts: {
            wa: null,
        },
    });
};

export default pageContent;

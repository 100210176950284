import { FunctionComponent, useRef, useEffect, useState } from "react";
import {
    Flex,
    Box,
    Button,
    Heading,
    useTheme,
} from "@smile2impress/components";

import { Theme, WindowStateSize } from "constants/enum";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { noop } from "utils/noop";

import { CookiesList } from "./CookiesList";
import { CookiesListMobile } from "./CookiesListMobile";
import { CookiesPolicyPopupProps } from "./CookiesPolicyPopup.types";

export const CookiesPolicyPopup: FunctionComponent<CookiesPolicyPopupProps> = ({
    title,
    accept,
    settings,
    content,
    onClosePopup = noop,
    onAcceptCookies = noop,
}) => {
    const { initialColorMode } = useTheme();
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const header = useRef<HTMLDivElement>(null);
    const footer = useRef<HTMLDivElement>(null);

    const { width } = useWindowDimensions();
    const isDesktop = width > WindowStateSize.TABLET_MAXIMUM_SIZE;

    useEffect(() => {
        const headerBlockHeight = header?.current?.clientHeight || 0;
        const footerBlockHeight = footer?.current?.clientHeight || 0;

        setHeaderHeight(headerBlockHeight);
        setFooterHeight(footerBlockHeight);
    }, []);

    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            bottom={0}
            right={0}
            zIndex="tooltip"
        >
            <Box
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                w="100%"
                h="100%"
                backgroundColor="base.overlay"
                onClick={onClosePopup}
            />
            <Flex
                position="absolute"
                top={[0, "50%"]}
                left={[0, "50%"]}
                w="100%"
                h={["100%", "520px"]}
                maxW="680px"
                flexDir="column"
                borderRadius={["none", "base"]}
                pb={14}
                backgroundColor={
                    initialColorMode === Theme.DARK
                        ? "base.spaceGrey"
                        : "base.primary"
                }
                overflow={["auto", "hidden"]}
                transform={["none", "translate3d(-50%, -50%, 0)"]}
            >
                <Heading
                    textStyle="title2"
                    px={[3, 5]}
                    py={4}
                    borderBottom="1px solid"
                    borderColor="line.secondary"
                >
                    {title}
                </Heading>
                {isDesktop ? (
                    <CookiesList content={content} />
                ) : (
                    <CookiesListMobile
                        content={content}
                        headerHeight={headerHeight}
                        footerHeight={footerHeight}
                    />
                )}

                <Flex
                    position="absolute"
                    left={0}
                    bottom={0}
                    flexDir="column-reverse"
                    alignItems="center"
                    w="100%"
                    display={["flex", "block"]}
                    textAlign={["left", "right"]}
                    py={2}
                    px={[3, 2]}
                    backgroundColor={
                        initialColorMode === Theme.DARK
                            ? "base.spaceGrey"
                            : "base.primary"
                    }
                    borderTop=" 1px solid rgba(66, 78, 88, 0.1)"
                    ref={footer}
                >
                    <Button
                        onClick={onAcceptCookies}
                        variant="ghost"
                        mr={[0, 3]}
                    >
                        {settings}
                    </Button>
                    <Button onClick={onClosePopup}>{accept}</Button>
                </Flex>
            </Flex>
        </Box>
    );
};

import { Translate } from "next-translate";

import { CommonPageContentProps } from "content/content.types";

import defaultContent from "./common";

const pageContent = (
    t: Translate,
    { version, language }: CommonPageContentProps
) => defaultContent(t, { version, language });

export default pageContent;

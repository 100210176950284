import { createContext, useContext } from "react";

type ContextProps = {
    isExperiment: boolean;
    experimentGroupId: number;
};

export const ExperimentContext = createContext<ContextProps>(
    {} as ContextProps
);

export const useExperimentContext = () => useContext(ExperimentContext);

import { useState, useEffect } from "react";
import { Auth } from "@aws-amplify/auth";

import { AuthStatuses } from "constants/enum";

export const useCheckAuthentication = () => {
    const [authStatus, setAuthStatus] = useState<AuthStatuses>(
        AuthStatuses.Initial
    );

    const isPendingAuth = authStatus === AuthStatuses.Pending;
    const isSuccessAuth = authStatus === AuthStatuses.Success;

    const checkAuthentication = async () => {
        try {
            setAuthStatus(AuthStatuses.Pending);

            await Auth.currentAuthenticatedUser();

            setAuthStatus(AuthStatuses.Success);
        } catch {
            setAuthStatus(AuthStatuses.Error);
        }
    };

    useEffect(() => {
        checkAuthentication();
    }, []);

    return [isPendingAuth, isSuccessAuth];
};

export const LOGO_WIDTH = 160;
export const HEADER_GAPS = 128;
export const REGIONS_WIDTH = 88;
export const HEADER_CONTAINER_MAX_WIDTH = 1600;

export enum ACTION_BUTTON_WIDTH {
    es = 144,
    pt = 204,
    fr = 275,
    it = 252,
    en = 230,
    de = 225,
    uk = 307,
}

export enum LOGIN_BUTTON_WIDTH {
    es = 144,
    pt = 204,
    fr = 275,
    it = 252,
    en = 230,
    de = 230,
    uk = 230,
}

export enum AVERAGE_ELEMENT_WIDTH {
    es = 133,
    pt = 130,
    fr = 155,
    it = 116,
    en = 108,
    de = 100,
    uk = 125,
}

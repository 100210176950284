import React, { FunctionComponent, useEffect } from "react";
import classNames from "classnames/bind";

import { CountryVersion } from "constants/enum";

import { SourceProps, WhatsappWidgetProps } from "./WhatsappWidget.types";
import styles from "./WhatsappWidget.module.scss";

const cx = classNames.bind(styles);

export const WhatsappWidget: FunctionComponent<WhatsappWidgetProps> = ({
    number,
    className,
    pre_filled_message,
    hide,
    version,
    source = SourceProps.FROM_SITE,
}) => {
    useEffect(() => {
        const waBnt = document.querySelector("#wa-msg-us-btn");

        if (waBnt) return;

        const script = document.createElement("script");
        script.src = "https://cdn.smooch.io/whatsapp/message-us-btn.min.js";
        document.body.appendChild(script);
    }, []);

    return (
        <div
            className={cx(
                "root",
                `root_source-${source}`,
                className,
                hide && "hide",
                {
                    "root_version-germany": version === CountryVersion.GERMANY,
                }
            )}
        >
            <div
                className="wa-message-us"
                // @ts-ignore
                number={number}
                label=""
                pre_filled_message={pre_filled_message}
                color="green"
                size="compact"
                border_radius="2px"
            />
        </div>
    );
};

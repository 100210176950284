import { useState, useEffect } from "react";

export const useWindowDimensions = () => {
    const hasWindow = typeof window !== "undefined";

    const [windowDimensions, setWindowDimensions] = useState({
        height: hasWindow ? window.innerHeight : 0,
        width: hasWindow ? window.innerWidth : 0,
    });

    const handleResize = () => {
        setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};

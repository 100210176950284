import { FC, useState } from "react";
import {
    Box,
    Flex,
    Icon,
    ChevronRightThinOutlineIcon,
    ChevronLeftOutlineIcon,
    CheckmarkThinOutlineIcon,
    Text,
    useTheme,
} from "@smile2impress/components";

import { FlagIcon } from "components/Icons/FlagIcon";
import { LanguageType } from "services/RegionsAndLanguagesService/RegionsAndLanguagesService.types";
import { Regions } from "components/Regions";
import { Theme } from "constants/enum";

import { RegionsLanguagesMobileProps } from "./RegionsLanguagesMobile.types";

export const RegionsLanguagesMobile: FC<RegionsLanguagesMobileProps> = ({
    content,
    regions,
    languages,
    version,
    onRedirectTo,
    currentRegion,
    onChangeLanguage,
    onBackToMenu,
}) => {
    const { initialColorMode } = useTheme();
    const { regionTitle, languageTitle } = content;
    const [isRegionsActive, setIsRegionsActive] = useState(false);

    const handleSwitchRegions = () => {
        setIsRegionsActive(!isRegionsActive);
    };

    return (
        <Box
            position="relative"
            height="100%"
            pl={3}
            pr={3}
            pt={10}
            backgroundColor={
                initialColorMode === Theme.DARK
                    ? "base.spaceGrey"
                    : "base.primary"
            }
            textAlign="left"
        >
            <Box
                as="button"
                position="absolute"
                top={0}
                left={0}
                p={0}
                border="none"
                backgroundColor="transparent"
                w={7}
                h={7}
                onClick={isRegionsActive ? handleSwitchRegions : onBackToMenu}
            >
                <Icon as={ChevronLeftOutlineIcon} />
            </Box>
            <Text textStyle="title4" color="text.primary" mb={1}>
                {regionTitle}
            </Text>
            <Flex
                w="210%"
                justifyContent="space-between"
                mb={5}
                transition={isRegionsActive ? "transform 0.4s" : "none"}
                transform={
                    isRegionsActive
                        ? "translate3d(calc(-50% - 16px), 0, 0)"
                        : "none"
                }
            >
                <Box w="50%">
                    <Flex
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        pt={2}
                        pb={2}
                        cursor="pointer"
                        onClick={handleSwitchRegions}
                    >
                        <Flex position="relative" alignItems="center">
                            <Box w={3} h={3} pr={[2, 2, 0]}>
                                <FlagIcon version={version} />
                            </Box>

                            <Text textStyle="body" pl={1} pr={[2, 2, 0]}>
                                {currentRegion}
                            </Text>
                        </Flex>

                        <Icon as={ChevronRightThinOutlineIcon} mr={[2, 2, 0]} />
                    </Flex>
                </Box>
                {isRegionsActive && (
                    <Box w="50%">
                        <Regions
                            regions={regions}
                            onRedirectTo={onRedirectTo}
                            onSwitchRegions={handleSwitchRegions}
                        />
                    </Box>
                )}
            </Flex>
            {!isRegionsActive && (
                <div>
                    <Text textStyle="title4" color="text.primary">
                        {languageTitle}
                    </Text>
                    <Box as="ul" m={0} listStyleType="none" mb={2}>
                        {languages.map((language: LanguageType) => {
                            const { id, name, isActive } = language;

                            return (
                                <Flex
                                    display="flex"
                                    as="li"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    pt={2}
                                    pb={2}
                                    cursor="pointer"
                                    color="text.primary"
                                    key={id}
                                    onClick={() => onChangeLanguage(id)}
                                >
                                    {name}
                                    {isActive && (
                                        <Icon as={CheckmarkThinOutlineIcon} />
                                    )}
                                </Flex>
                            );
                        })}
                    </Box>
                </div>
            )}
        </Box>
    );
};

export default RegionsLanguagesMobile;
